import uniqueId from 'lodash.uniqueid'
import qs from 'qs'

export const interviewStatusClass = (status) => {
  switch (status) {
    case 'expired':
      return 'label-warning'

    case 'declined':
      return 'label-danger'

    case 'recorded':
      return 'label-info'

    case 'completed':
      return 'label-success'

    default:
      return 'label-default'
  }
}

export const credentialLetters = (firstName, lastName) => (
  (firstName.charAt(0) + lastName.charAt(0)).toUpperCase()
)

export const getScript = (source, callback) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script')
    let prior = document.getElementsByTagName('script')[0]

    script.async = 1
    script.onload = script.onreadystatechange = (_, isAbort) => {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null
        script = undefined

        if (!isAbort) {
          resolve()
        }
      }

      reject()
    }

    script.src = source
    prior.parentNode.insertBefore(script, prior)
  })
}

export const formId = () => uniqueId('form_')

export const getQuery = (query) => {
  return qs.parse(query, { ignoreQueryPrefix: true })
}