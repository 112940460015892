import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { injectIntl } from 'react-intl'
import { credentialLetters, interviewStatusClass as statusClass } from '../helpers'
import NotFound from './NotFoundListItem'
import { Date } from './Date'

const VideoInterviews = ({ interviews, onClick, intl }) => {
  const items = interviews.map((interview, i) => (
    <div
      key={interview.id.toString()}
      className={c('item', {'item-odd': (i + 1) % 2 !== 0, 'item-even': (i + 1) % 2 === 0})}
      onClick={() => onClick(interview)}
    >
      <div className="column">
        <span>
          {credentialLetters(
            interview.candidate.firstName,
            interview.candidate.lastName
          )}
        </span>
      </div>

      <div className="column candidate-info">
        {interview.candidate.firstName + ' ' + interview.candidate.lastName}

        <span className="email">
          {interview.candidate.email}
          </span>
      </div>

      <div className="column job-position">
        {interview.jobPositinoLabel}
      </div>

      <div className="column status">
        <div className={c('label', statusClass(interview.status))}>
          {intl.formatMessage({id: `videoInterviews.status.${interview.status}`})}
        </div>
      </div>

      <div className="column date">
        <Date from={interview.expiryDate} />
      </div>

      {/*<div className="column column-actions">
        <ul>
          <li>
            <a href="">I like</a>
          </li>

          <li>
            <a href="">No way</a>
          </li>
        </ul>
      </div>*/}
    </div>
  ))

  return (
    <div className="item-list item-list-hover" id="videointerview-list">
      <div className="item header">
        <div className="column" />
        <div className="column candidate-info" />
        <div className="column job-position">
          {intl.formatMessage({id: 'videoInterviews.list.jobPositionColumn'})}
        </div>
        <div className="column status">
          {intl.formatMessage({id: 'videoInterviews.list.statusColumn'})}
        </div>
        <div className="column date">
          {intl.formatMessage({id: 'videoInterviews.list.expiryDateColumn'})}
        </div>
        {/*<div className="column column-actions" />*/}
      </div>

      {items}

      {interviews.length === 0 &&
        <NotFound message={intl.formatMessage({id: 'videoInterviews.list.blankslate'})} />
      }
    </div>
  )
}

VideoInterviews.propTypes = {
  interviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      expiryDate: PropTypes.string.isRequired,
      candidate: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }).isRequired,
      jobPositinoLabel: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default injectIntl(VideoInterviews)
