import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import IntroductionVideo from '../components/VideoInterviewIntroductionVideoStep'

class VideoInterviewIntroductionVideoStep extends Component {

  render() {
    return (
      <IntroductionVideo {...this.props} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    nextStep: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview/invite/details')
    },
    cancel: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview')
    },
  }
}

export default withRouter(connect(mapStateToProps)(VideoInterviewIntroductionVideoStep))
