import * as actionType from '../constants/actionTypes'

const initialState = {
  loaded: false,
  list: [],
  filter: {
    search: null,
    languageId: null,
  },
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.QUESTION_SETS_DATA_LOAD:
      return {
        ...state,
        loaded: true,
        list: action.sets,
      }

    case actionType.QUESTION_SETS_FILTER:
      let filters = {}

      // convert to int
      if (action.filters.languageId) {
        filters.languageId = parseInt(action.filters.languageId, 10)
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filters,
          ...filters,
        },
      }

    case actionType.QUESTION_SETS_REMOVE:
      const newSets = state.list.filter(s => s.id !== action.id)

      return {
        ...state,
        list: newSets,
      }

    default:
      return state
  }
}
