import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import NotFoundListItem from './NotFoundListItem'

/*const factory = (WrappedComponent, item) => {
  return (props) => (
    <WrappedComponent
      item={item}
      {...props}
    />
  )
}*/

const Item = ({ item, even, iterator, index, ...otherProps }) => (
  <div
    className={c('item', {'item-even': even, 'item-odd': !even})}
    {...otherProps}
  >
    {item}
  </div>
)

const ItemList = ({ items, component, notFound }) => (
  <div className="item-list">
    {items.map((item, i) => {
      let even = (i + 1) % 2 === 0
      let componentProps = {
        key: i,
        even,
        index: i,
        iterator: i + 1,
      }

      if (component) {
        item = component(item, componentProps)
      } else {
        item = <Item item={item} {...componentProps} />
      }

      return item
    })}

    {notFound && items.length === 0 && (
      (typeof notFound === 'string' && <NotFoundListItem message={notFound} />)
      || notFound
    )}
  </div>
)

ItemList.propTypes = {
  items: PropTypes.array.isRequired,
  component: PropTypes.func,
  notFound: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

export default ItemList
