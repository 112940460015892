import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from 'react-breadcrumbs'
import { FormattedMessage, injectIntl } from 'react-intl'
import NavLink from './NavLink'
import User from './User'
import LanguageSwitcher from './LanguageSwitcher'

const App = (
  {
    routes,
    params,
    children,
    identity,
    instance,
    languages,
    currentLanguage,
    onLanguageChange,
    intl,
  }) => (
  <div className="container">
    <div className="header">
      <div className="logo-area">
        <a href={identity.baseUrl || instance.baseUrl} className="logo-area-link">
          <span>{instance.name}</span>
        </a>
      </div>

      <User name={identity.firstName + " " + identity.lastName}
            clientName={identity.clientName} />
    </div>

    <LanguageSwitcher languages={languages}
                      current={currentLanguage}
                      onChange={onLanguageChange} />

    <div className="top-level-navigation">
    </div>

    <div className="hub-primary-navigation">
      <ul>
        <li className="item">
          <a href={identity.baseUrl || instance.baseUrl}>
            <i className="fa fa-home"/>
            <FormattedMessage id={'primaryNavigation.home'} />
          </a>
        </li>

        <NavLink to="/video-interview"
                 className="item">
          <i className="fa fa-video-camera"/>
          <FormattedMessage id={'videoInterviews.navigationLabel'} />
        </NavLink>

        <NavLink to="/library" className="item">
          <i className="fa fa-briefcase"/>
          <FormattedMessage id={'library.navigationLabel'} />
        </NavLink>

        <NavLink to="/settings" className="item">
          <i className="fa fa-wrench"/>
          <FormattedMessage id={'settings.navigationLabel'} />
        </NavLink>
      </ul>
    </div>

    {/*todo: až po navigation!
    <Breadcrumbs
      routes={routes.map(route => {
        if (route.name && !route.nameTranslated) {
          route.name = intl.formatMessage({id: route.name})
          route.nameTranslated = true
        }

        return route
      })}
      params={params}
      separator=" / "
      displayMissing={false}
      wrapperClass="breadcrumb"
    />*/}

    {children}
  </div>
)

App.propTypes = {
  children: PropTypes.element.isRequired,
  identity: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  currentLanguage: PropTypes.object.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
}

export default injectIntl(App)
