import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { updateDefinitionLanguage, updateDefinitionExpiry, updateDefinitionReminder } from '../actions/videoInterviews'
import { loadEmailTemplates } from '../actions/emailTemplates'
import Details from '../components/VideoInterviewDetailsStep'
import Spinner from '../components/Spinner'
import { filterVideoInterviewLanguages, visibleEmailTemplates, getInvitationLanguage } from '../selectors'

class VideoInterviewDetailsStep extends Component {

  constructor(props) {
    super()

    props.dispatch(loadEmailTemplates())

    if (props.emailTemplates.length === 0) {
      props.dispatch(updateDefinitionLanguage(props.languageId))
    }

    this.state = {
      emails: {},
    }

    this.toggleEmailTemplate = this.toggleEmailTemplate.bind(this)
  }

  toggleEmailTemplate(template, e) {
    e.preventDefault()

    this.setState({
      emails: {
        ...this.state.emails,
        [template.id]: !this.state.emails[template.id],
      }
    })
  }

  render() {
    const { loaded, emailTemplates, ...otherProps } = this.props

    if (!loaded) {
      return (
        <Spinner />
      )
    }

    return (
      <Details
        {...otherProps}
        emailTemplates={visibleEmailTemplates(emailTemplates, this.state.emails)}
        toggleEmailTemplate={this.toggleEmailTemplate}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loaded: state.emailTemplates.loaded,
    emailTemplates: state.videoInterviewInvitation.emailTemplates,
    languages: filterVideoInterviewLanguages(state.languages.list),
    languageId: getInvitationLanguage(state),
    expiryDays: state.videoInterviewInvitation.expiryDays,
    reminderDays: state.videoInterviewInvitation.reminderDays,
    nextStep: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview/invite/summary')
    },
    cancel: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview')
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: action => dispatch(action),
    onChange: (event) => {
      let eventName = event.target.name
      let value = parseInt(event.target.value, 10)

      switch (eventName) {
        case 'languageId':
          return dispatch(updateDefinitionLanguage(value))

        case 'expiryDays':
          return dispatch(updateDefinitionExpiry(value))

        case 'reminderDays':
          return dispatch(updateDefinitionReminder(value))

        default:
          throw new Error('Unknown event to update video interview definition state.')
      }
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewDetailsStep))
