import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import c from 'classnames'
import { credentialLetters } from '../helpers'
import FormElement from './FormElement'

const renderTextArea = ({ input, label, placeholder, rows = 4, meta }) => (
  <div>
    {label &&
      <div className="form-label">
        <label>{label}</label>
      </div>
    }

    <div className="form-control">
      <textarea
        {...input}
        rows={rows}
        placeholder={placeholder}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
      />
    </div>
  </div>
)

const NoteForm = (
  {
    handleSubmit,
    submitting,
    onSubmit,
    identity: {
      firstName,
      lastName,
    },
    intl,
  }) => (
  <FormElement
    onSubmit={handleSubmit(onSubmit)}
    className="comment-form"
  >
    <div className="user">
      <span>{credentialLetters(firstName, lastName)}</span>
    </div>

    <div className="message">
      <Field
        name="content"
        rows="5"
        component={renderTextArea}
      />

      <input
        type="submit"
        value={intl.formatMessage({id: 'videoInterviews.interviewDetail.comments.submitButton.label'})}
        className="button"
        disabled={submitting}
      />
    </div>
  </FormElement>
)

NoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  identity: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
}

const validate = values => {
  const errors = {}

  // validate content
  if (!values.content) {
    errors.content = 'Required'
  }

  return errors
}

export default injectIntl(reduxForm({
  form: 'videoInterviewComment',
  validate: validate,
})(NoteForm))
