import { putProfile, postLogo, deleteLogo } from './api'
import * as actionTypes from '../constants/actionTypes'

export function updateProfile(values) {
  return (dispatch, getState) => {
    let { logo, ...profileValues } = values
    const state = getState()

    let profileValues2 = { ...profileValues, Id: state.identity.clientId, PartnerId: state.instance.id, Active: 1 };

    return Promise.all([
      putProfile(state.identity.clientId, profileValues2),
      logo && logo.length !== 0 ? postLogo(state.identity.clientId, logo[0]) : null,
    ]).then(responses => {
      dispatch({
        type: actionTypes.PROFILE_UPDATE,
        profile: profileValues,
      })

      if (responses[1]) {
        dispatch({
          type: actionTypes.PROFILE_LOGO_UPDATE,
          logo: responses[1].data.url,
        })
      }
    })
  }
}

export function removeLogo() {
  return (dispatch, getState) => {
    return deleteLogo()
      .then(() => {
        dispatch({
          type: actionTypes.PROFILE_LOGO_REMOVE,
        })
      })
  }
}
