import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { injectIntl } from 'react-intl'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import Duration from './Duration'
import ItemList from './ItemList'
import NotFoundListItem from './NotFoundListItem'

const Handle = SortableHandle(({ iterator }) => (
  <div className="column column-iterator">
    {iterator}
  </div>
))

const SortableItem = SortableElement(({languages, item, onUnSelect, iterator, even, intl}) =>
  <div className={c('item', {'item-even': even, 'item-odd': !even})}>
    <Handle iterator={iterator} />

    <div className="column">
      <span className="question-text alt">{item.text}</span>

      {' '}

      (
      <span className="question-duration">
        <Duration seconds={item.duration} />
      </span>
      ),

      {' '}

      <span className="question-language">{languages.find(x => x.id === item.languageId)?.name}</span>
    </div>

    <div className="column column-actions">
      <ul>
        <li>
          <a href="#" onClick={(e) => {
            e.preventDefault()
            onUnSelect(item)
          }}>
            <i className="fa fa-times" />
            {intl.formatMessage({id: 'questionSets.addSet.selectedQuestions.removeAction.label'})}
          </a>
        </li>
      </ul>
    </div>
  </div>
)

const SortableList = SortableContainer(({questions, invalid, ...otherProps}) => (
  <ItemList
    items={questions}
    notFound={
      <NotFoundListItem
        message={otherProps.intl.formatMessage({id: 'questionSets.addSet.selectedQuestions.emptyslate'})}
        invalid={invalid}
      />
    }
    component={(item, itemProps) => (
      <SortableItem
        item={item}
        {...otherProps}
        {...itemProps}
        index={itemProps.index}
      />
    )}
  />
))

const SelectedQuestions = ({onSort, ...props}) => (
  <SortableList
    {...props}
    onSortEnd={onSort}
    useDragHandle={true}
    helperClass="sortable-item"
  />
)

SelectedQuestions.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,  
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      languageId: PropTypes.number.isRequired,
    })
  ).isRequired,
  onUnSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
}

export default injectIntl(SelectedQuestions)
