import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { loadCountries } from '../actions/countries'
import { updateProfile, removeLogo } from '../actions/client'
import ProfileForm from '../components/ProfileForm'
import Spinner from '../components/Spinner'



class Profile extends Component {

  constructor(props) {
    super()

    props.dispatch(loadCountries())
  }

  render() {
    const { loaded, ...otherProps } = this.props

    if (!loaded) {
      return (
        <Spinner />
      )
    }

    return (
      <ProfileForm
        {...otherProps}
        initialValues={{
          logo: null,
        }}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.countries.loaded,
    countries: state.countries.list,
    clientName: state.identity.clientName,
    clientLogo: state.identity.clientLogo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: action => dispatch(action),
    onSubmit: values => dispatch(updateProfile(values)),
    onCancel: event => {
      event.preventDefault()
      dispatch(reset('profile'))
    },
    onLogoRemove: event => {
      event.preventDefault()
      dispatch(removeLogo())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
