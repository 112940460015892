import * as actionType from '../constants/actionTypes'
import * as forms from '../constants/forms'

const initialState = {
  loaded: false,
  list: [],
  filter: {
    search: null,
    sortBy: forms.FILTER_ALPHABET,
  },
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.TAGS_DATA_LOAD:
      return {
        ...state,
        loaded: true,
        list: action.tags,
      }

    case actionType.TAGS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      }

    case actionType.TAGS_REMOVE:
      const tags = state.list.filter(t => t.id !== action.id)

      return {
        ...state,
        list: tags,
      }

    case actionType.TAGS_DATA_REFRESH:
      return {
        ...state,
        list: action.tags,
      }

    default:
      return state
  }
}
