import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router"
import { Link } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { formId } from '../helpers'

const ExpirySelect = ({ min = 1, max = 30, intl, ...otherProps }) => {
  let items = []

  for (let value = min; value <= max; value++) {
    items.push(
      <FormattedMessage
        key={`expiry_interval_${value}`}
        id="videoInterviews.invite.expiryInterval"
        values={{
          days: `${value} ${intl.messages['videoInterviews.invite.days'][intl.formatPlural(value)]}`,
        }}
      >
        {message => <option value={value}>{message}</option>}
      </FormattedMessage>
    )
  }

  return (
    <select {...otherProps}>
      {items}
    </select>
  )
}

ExpirySelect.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
}

const TranslatedExpirySelect = injectIntl(ExpirySelect)

const EmailTemplateItem = ({ id, subject, content, typeName, form, visible = false, toggle, intl }) => (
  <div className="item">
    <div className="header">
      <h3 className="h">{intl.formatMessage({id: `emailTemplates.type.${typeName}`})}</h3>
      {' '}
      <a href="#" onClick={toggle}>
        {visible
          ? intl.formatMessage({id: 'videoInterviews.invite.details.emails.hideMessageButton.label'})
          : intl.formatMessage({id: 'videoInterviews.invite.details.emails.showMessageButton.label'})
        }
      </a>
    </div>

    {visible && form &&
      <div className="content" style={{ maxHeight: 'none' }}>
        {form}
      </div>
    }

    {visible && !form &&
      <div className="content">
        <p className="title">
          {intl.formatMessage({id: 'videoInterviews.invite.details.emails.previewSubject'})}
          {' '}
          {subject}

          <Link to={`/video-interview/invite/details/${id}`} className="edit-link">
            {intl.formatMessage({id: 'videoInterviews.invite.details.emails.editButton.label'})}
          </Link>
        </p>

        <pre>{content}</pre>
      </div>
    }
  </div>
)

const TranslatedEmailTemplatedItem = injectIntl(EmailTemplateItem)

EmailTemplateItem.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  typeName: PropTypes.string.isRequired,
  form: PropTypes.element,
  visible: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
}

const VideoInterviewDetailsStep = (
  {
    emailTemplates,
    languages,
    languageId,
    expiryDays,
    reminderDays,
    onChange,
    match,
    emailTemplateForm,
    nextStep,
    cancel,
    toggleEmailTemplate,
    intl,
  }) => {
  const languageFieldId = formId()
  const expiryFieldId = formId()
  const reminderFieldId = formId()

  return (
    <div>
      <div className="content step-form-content">
        <h2>{intl.formatMessage({id: 'videoInterviews.invite.details.language.title'})}</h2>
        <p>{intl.formatMessage({id: 'videoInterviews.invite.details.language.description'})}</p>

        <div className="form-row">
          <label htmlFor={languageFieldId} className="form-label">
            {intl.formatMessage({id: 'videoInterviews.invite.details.language.switchNote'})}
          </label>

          {' '}

          <select
            name="languageId"
            value={languageId}
            id={languageFieldId}
            onChange={onChange}
            className="input"
          >
            {languages.map(language => (
              <option
                key={language.id.toString()}
                value={language.id}
              >
                {language.name}
              </option>
            ))}
          </select>
        </div>

        <h2>{intl.formatMessage({id: 'videoInterviews.invite.details.dates.title'})}</h2>
        <p>{intl.formatMessage({id: 'videoInterviews.invite.details.dates.description'})}</p>

        <div className="form-row">
          <label htmlFor={expiryFieldId} className="form-label">
            {intl.formatMessage({id: 'videoInterviews.invite.details.dates.expiryField.label'})}
          </label>

          {' '}

          <TranslatedExpirySelect
            name="expiryDays"
            value={expiryDays}
            id={expiryFieldId}
            onChange={onChange}
            className="input"
          />
        </div>

        <div className="form-row">
          <label htmlFor={reminderFieldId} className="form-label">
            {intl.formatMessage({id: 'videoInterviews.invite.details.dates.reminderField.label'})}
          </label>

          {' '}

          <select
            name="reminderDays"
            value={reminderDays}
            id={reminderFieldId}
            onChange={onChange}
            className="input"
          >
            {[5, 4, 3, 2, 1].map(days => (
              <FormattedMessage
                key={`reminder_interval_${days}`}
                id="videoInterviews.invite.reminderInterval"
                values={{
                  days: `${days} ${intl.messages['videoInterviews.invite.days'][intl.formatPlural(days)]}`,
                }}
              >
                {message => <option value={days}>{message}</option>}
              </FormattedMessage>
            ))}
          </select>
        </div>

        <h2>{intl.formatMessage({id: 'videoInterviews.invite.details.emails.title'})}</h2>
        <p>{intl.formatMessage({id: 'videoInterviews.invite.details.emails.description'})}</p>

        <div className="accordion">
          {emailTemplates.map(template => (
            <TranslatedEmailTemplatedItem
              key={template.id.toString()}
              id={template.id}
              subject={template.subject}
              content={template.content}
              typeName={template.typeName}
              form={match.params.id && template.id === parseInt(match.params.id, 10) ? emailTemplateForm : null}
              visible={template.visible}
              toggle={e => toggleEmailTemplate(template, e)}
            />
          ))}
        </div>
      </div>

      <div className="footer step-form-footer">
        <a href="#" className="button" onClick={nextStep}>
          {intl.formatMessage({id: 'videoInterviews.invite.nextStepButton.label'})}
        </a>

        <span className="cancel">
          {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.conjunction'})}
          {' '}

          <a href="#" onClick={cancel}>
            {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.label'})}
          </a>
        </span>
      </div>
    </div>
  )
}

VideoInterviewDetailsStep.propTypes = {
  emailTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      subject: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
    })
  ).isRequired,
  languages: PropTypes.array.isRequired,
  languageId: PropTypes.number.isRequired,
  expiryDays: PropTypes.number.isRequired,
  reminderDays: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  toggleEmailTemplate: PropTypes.func.isRequired,
}

export default injectIntl(withRouter(VideoInterviewDetailsStep))
