import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import c from 'classnames'
import validator from 'validator'
import { sumTotalDuration } from '../selectors'
import FormElement from './FormElement'
import Duration from './Duration'
import SelectedQuestions from './SelectedQuestions'
import { formId } from '../helpers'
import { toNumber } from '../helpers/normalize'

const languageOptions = languages => {
  let options = []

  options.push(languages.map(language => (
    <option key={language.id.toString()} value={language.id}>{language.name}</option>
  )))

  return options
}

const renderField = ({ input, label, type = 'text', meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <input
        {...input}
        type={type}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const renderSelect = ({ input, label, items, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <select
        {...input}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      >
        {items}
      </select>
    </div>
  </div>
)

const QuestionSetForm = (
  {
    handleSubmit,
    submitting,
    languages,
    allLanguages,
    onSubmit,
    onCancel,
    questions,
    onQuestionUnSelect,
    onQuestionSort,
    submitFailed,
    intl,
  }) => (
  <FormElement onSubmit={handleSubmit(onSubmit)}>
    <div className="form-row">
      <Field
        label={intl.formatMessage({id: 'questionSets.addSet.titleField.label'})}
        name="name"
        component={renderField}
      />
    </div>

    <div className="form-row">
      <div className="form-column">
        <Field
          label={intl.formatMessage({id: 'questionSets.addSet.languageField.label'})}
          name="languageId"
          component={renderSelect}
          items={languageOptions(languages)}
          normalize={toNumber}
        />
      </div>

      <div className="form-column">
        {intl.formatMessage({id: 'questionSets.addSet.totalDuration'})}
        {' '}
        <span>
          <Duration seconds={sumTotalDuration(questions)} />
        </span>
      </div>
    </div>

    <div className="form-line" />

    <div className="form-row question-set-form-attached-questions">
      <h2 className="item-list-title">
        {intl.formatMessage({id: 'questionSets.addSet.selectedQuestions.title'})}
      </h2>

      <SelectedQuestions
        languages={allLanguages}
        questions={questions}
        onUnSelect={onQuestionUnSelect}
        onSort={onQuestionSort}
        invalid={submitFailed && questions.length === 0}
      />
    </div>

    <div className="form-line" />

    <div className="form-submit-row">
      <input
        type="submit"
        value={intl.formatMessage({id: 'questionSets.addSet.submitButton.label'})}
        className="button"
        disabled={submitting}
      />

      <span className="cancel">
        {intl.formatMessage({id: 'questionSets.addSet.cancelButton.conjunction'})}
        {' '}

        <Link
          to={typeof onCancel === 'string' ? onCancel : '#'}
          onClick={typeof onCancel === 'function' ? onCancel : null}
        >
          {intl.formatMessage({id: 'questionSets.addSet.cancelButton.label'})}
        </Link>
      </span>
    </div>
  </FormElement>
)

QuestionSetForm.propTypes = {
  languages: PropTypes.array.isRequired,
  allLanguages: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  questions: PropTypes.array.isRequired,
  onQuestionUnSelect: PropTypes.func.isRequired,
}

const validate = (values, props) => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  } else if (!validator.isLength(values.name, { max: 255 })) {
    errors.name = 'Too long'
  }

  if (!values.languageId) {
    errors.languageId = 'Required'
  }

  return errors
}

export default injectIntl(reduxForm({
  form: 'questionSet',
  validate: validate,
  enableReinitialize: true,
  pure: false,
})(QuestionSetForm))
