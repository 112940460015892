import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { loadSets, filterSets, removeSet } from '../actions/sets'
import { filterSets as filterSetsSelector, filterVideoInterviewLanguages } from '../selectors'
import { Link } from 'react-router-dom'
import QuestionSetList from '../components/QuestionSetList'
import QuestionSetFilter from '../components/QuestionSetFilter'
import Spinner from '../components/Spinner'

class QuestionSets extends Component {

  constructor() {
    super()

    this.filter = this.filter.bind(this)
    this.removeSet = this.removeSet.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(loadSets())
  }

  filter(filters) {
    this.props.dispatch(filterSets(filters))
  }

  removeSet(id) {
    this.props.dispatch(removeSet(id))
  }

  render() {
    const { loaded, sets, languages, allLanguages, intl } = this.props

    let actions = [
      {
        label: intl.formatMessage({id: 'questionSets.list.editAction.label'}),
        iconClassName: 'fa-pencil',
        link: (set) => {
          return `/library/question-set/update/${set.id}`
        }
      },
      {
        label: intl.formatMessage({id: 'questionSets.list.removeAction.label'}),
        iconClassName: 'fa-times',
        handle: (event, set) => {
          event.preventDefault()
          this.removeSet(set.id)
        }
      }
    ]

    return (
      <div>
        <div className="create-action">
          <Link to="/library/question-set/create" className="button create">
            <i className="fa fa-plus-circle" />
            {intl.formatMessage({id: 'questionSets.addButton.label'})}
          </Link>
        </div>

        <QuestionSetFilter onFilter={this.filter}
                           languages={languages} />

        {!loaded &&
          <Spinner />
        }

        {loaded &&
          <QuestionSetList
            languages={allLanguages}
            sets={sets}
            actions={actions}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.sets.loaded,
    sets: filterSetsSelector(state.sets.list, state.sets.filter),
    languages: filterVideoInterviewLanguages(state.languages.list),
    allLanguages: state.languages.list,
  }
}

export default injectIntl(connect(mapStateToProps)(QuestionSets))
