import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

const NotFoundListItem = ({ message, invalid = false }) => (
  <div className={c('empty-container', { 'empty-container-invalid': invalid })}>
    <p>{message}</p>
  </div>
)

NotFoundListItem.propTypes = {
  message: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
}

export default NotFoundListItem
