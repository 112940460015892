import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { removeCandidate } from '../actions/videoInterviews'
import Candidates from '../components/VideoInterviewCandidatesStep'

class VideoInterviewCandidatesStep extends Component {

  render() {
    return (
      <Candidates {...this.props} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    candidates: state.videoInterviewInvitation.candidates,
    jobPosition: state.videoInterviewInvitation.jobPosition || '',
    nextStep: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview/invite/questions')
    },
    cancel: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview')
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => dispatch(action),
    onCandidateDelete: candidate => dispatch(removeCandidate(candidate)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewCandidatesStep))
