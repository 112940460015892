import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import momentTimezone from 'moment-timezone' // eslint-disable-line no-unused-vars

const formatDate = ({ from, format, timezone }) => (
  <span>
    {moment(from).tz(timezone).format(format)}
  </span>
)

const Date = connect((state, ownProps) => {
  return {
    timezone: ownProps.timezone || state.instance.defaultTimezone,
    format: ownProps.format || state.instance.dateFormat,
  }
})(props => formatDate({...props}))

Date.propTypes = {
  from: PropTypes.string.isRequired,
  format: PropTypes.string,
  timezone: PropTypes.string,
}

const Time = connect((state, ownProps) => {
  return {
    timezone: ownProps.timezone || state.instance.defaultTimezone,
    format: ownProps.format || state.instance.timeFormat,
  }
})(props => formatDate({...props}))

Time.propTypes = {
  from: PropTypes.string.isRequired,
  format: PropTypes.string,
  timezone: PropTypes.string,
}

const DateTime = connect((state, ownProps) => {
  return {
    timezone: ownProps.timezone || state.instance.defaultTimezone,
    format: ownProps.format || (state.instance.dateFormat + ' ' + state.instance.timeFormat),
  }
})(props => formatDate({...props}))

DateTime.propTypes = {
  from: PropTypes.string.isRequired,
  format: PropTypes.string,
  timezone: PropTypes.string,
}

export { Date }
export { Time }
export { DateTime }
