import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

const TickIcon = ({ active, onSetDefault }) => {
  if (!active) {
    return (
      <a href="#"
         className="icon icon-tick"
         onClick={onSetDefault} />
    )
  }

  return (
    <span className="icon icon-tick icon-tick-green" />
  )
}

TickIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  onSetDefault: PropTypes.func.isRequired,
}

const LanguageState = ({ translated, active, onSetDefault }) => {
  if (!translated) {
    return <span>-</span>
  }

  return <TickIcon active={active}
                   onSetDefault={onSetDefault} />
}

LanguageState.propTypes = {
  translated: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  onSetDefault: PropTypes.func.isRequired,
}

const LanguageItem = ({ language, onSetDefault, onDeactivate, intl }) => (
  <div className="item">
    <div className="column column-20">
      {language.name}
      {' '}

      {!language.isAppDefault && !language.isRecorderDefault &&
        <a className="languages-deactivate"
           href="#"
           title={intl.formatMessage({id: 'languages.list.deactivateAction.title'})}
           onClick={(event) => { onDeactivate(event, language) }}>
          {intl.formatMessage({id: 'languages.list.deactivateAction.label'})}
        </a>
      }
    </div>

    <div className="column column-10">
      <LanguageState onSetDefault={(event) => { onSetDefault(event, language, 'IsAppDefault') }}
                     translated={language.isAppTranslated}
                     active={language.isAppDefault} />
    </div>

    <div className="column column-10">
      <LanguageState onSetDefault={(event) => { onSetDefault(event, language, 'IsRecorderDefault') }}
                     translated={language.isRecorderTranslated}
                     active={language.isRecorderDefault} />
    </div>
  </div>
)

LanguageItem.propTypes = {
  language: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isAppTranslated: PropTypes.bool.isRequired,
    isRecorderTranslated: PropTypes.bool.isRequired,
    isAppDefault: PropTypes.bool.isRequired,
    isRecorderDefault: PropTypes.bool.isRequired,
  }).isRequired,
  onSetDefault: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
}

const ActivatedLanguages = ({ languages, onSetDefault, onDeactivate, intl }) => {
  const items = languages.map((language) => (
    <LanguageItem key={language.id.toString()}
                  language={language}
                  onSetDefault={onSetDefault}
                  onDeactivate={onDeactivate}
                  intl={intl} />
  ))

  return (
    <div>
      <div className="create-action">
        <Link to="/settings/language/activate" className="button">
          {intl.formatMessage({id: 'languages.addButton.label'})}
        </Link>
      </div>

      <div className="inline-form">
        <div className="item-list">
          {items}
        </div>
      </div>
    </div>
  )
}

ActivatedLanguages.propTypes = {
  languages: PropTypes.array.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
}

export default injectIntl(ActivatedLanguages)
