import { loadLanguages, changeLanguage } from './languages'
import { getInstance, setHash, getIdentity } from './api'
import * as actionTypes from '../constants/actionTypes'
import { getQuery } from '../helpers'
import { matchPath } from "react-router";
import { convertToMp4 } from './browserSpecific'

const getUrlPlaceholders = () => ({
  url: encodeURIComponent(window.location.href),
})

function catchPartnerError() {
  return (dispatch, getState) => {
    const urlPlaceholders = getUrlPlaceholders()

    let authUrl = getState().instance.authUrl.replace(/<([a-zA-Z]+)>/g, (match, placeholder) => {
      if (urlPlaceholders[placeholder]) {
        return urlPlaceholders[placeholder]
      }

      return ''
    })

    window.location = authUrl
  }
}

function loadIdentity() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().identity) {
      return Promise.resolve()
    }

    // fetch data from api
    return getIdentity(convertToMp4)
      .then((response) => {
        dispatch({
          type: actionTypes.IDENTITY_DATA_LOAD,
          identity: response.data,
        })
      })
  }
}

function loadInstance(partner) {
  return (dispatch, getState) => {
    // we already have data
    if (getState().instance) {
      return Promise.resolve()
    }

    return getInstance(partner)
      .then((response) => {
        dispatch({
          type: actionTypes.INSTANCE_DATA_LOAD,
          instance: response.data,
        })

        if (response.data.layoutCss) {
          let style = document.createElement('style')
          style.type = 'text/css'
          style.appendChild(document.createTextNode(response.data.layoutCss))
          document.head.appendChild(style)
        }
      })
  }
}

var loadInitialDataCalled = false;

export function loadInitialData(location, history) {
  return (dispatch, getState) => {
    if (loadInitialDataCalled) {
      return;
    }
    loadInitialDataCalled = true;

    const query = getQuery(location.search)
    var partner = query.partner
    const hash = query.auth

    if (partner) {
      try {
        localStorage.setItem("partner", partner)
      }
      catch {}
    } else {
      partner = localStorage.getItem("partner")
    }

    if (!partner) {
      //todo: error
    }

    Promise.all([
      dispatch(loadInstance(partner)),
    ])
      .then(() => {
        if (!hash) {
          throw new Error()
        }

        const initRedirect = matchPath(location.pathname, { path: '/:lang/video-interview/detail/:id' })
        setHash(hash)

        Promise.all([
          dispatch(loadIdentity()),
        ])
          .then(() => {
            // load languages
            dispatch(loadLanguages()).then(() => {
              let locale = null

              if (initRedirect) {
                let language = getState().languages.activated.find(l => l.code === initRedirect.params.lang && l.isAppTranslated)
                if (language) {
                  locale = language.code
                }
              }

              if (locale === null) {
                // check the language in the session
                let cookieLocale = localStorage.getItem("locale")
                // check if language from the cookie is available
                if (cookieLocale !== null) {
                  let language = getState().languages.activated.find(l => l.code === cookieLocale)

                  // language not found, unset language from the locale
                  if (language !== undefined) {
                    locale = language.code
                  }
                }
              }

              // we still don't have a language, select a default from the list of activated
              if (locale === null) {
                let language = getState().languages.activated.find(l => l.isAppDefault === true)
                locale = language.code
              }

              dispatch(changeLanguage(locale))
            })

            if (initRedirect) {
              history.replace('/video-interview/detail/' + initRedirect.params.id)
            } else if (getState().identity.candidates) {
              history.replace('/video-interview/invite')
            } else if (location.pathname === "/") {
              history.replace('/video-interview')
            } else {
              history.replace(location.pathname)
            }
          })
          .catch(() => {
            dispatch(catchPartnerError())
          })
      })
      .catch(() => {
        dispatch(catchPartnerError())
      })
  }
}
