import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import { formId } from '../helpers'

class QuestionSetFilter extends Component {

  constructor() {
    super()

    this.state = {
      search: '',
      languageId: 0,
    }

    this.focus = this.focus.bind(this)
    this.reset = this.reset.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.searchFieldId = formId()
    this.languageFieldId = formId()
  }

  componentDidMount() {
    this.focus()
  }

  focus() {
    this.searchInput.focus()
  }

  reset() {
    let state = {
      search: '',
      languageId: 0,
    }

    this.setState(state)
    this.props.onFilter(state)
  }

  handleSubmit(event) {
    event.preventDefault()
  }

  handleReset(event) {
    event.preventDefault()
    this.reset()
    this.focus()
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    let state = {
      [name]: value,
    }

    this.setState(state)
    this.props.onFilter(state)
  }

  handleKeyDown(event) {
    if (event.keyCode === 27) { // esc pressed
      event.preventDefault()
      this.reset()
    }
  }

  render() {
    const { intl } = this.props

    return (
      <div className="filter" id="question-set-list-filter">
        <FormElement onSubmit={this.handleSubmit}>
          <div className="filter-item">
            <label htmlFor={this.searchFieldId} className="label">
              {intl.formatMessage({id: 'questionSets.filter.searchField.label'})}
            </label>

            <input type="text"
                   name="search"
                   value={this.state.search}
                   autoComplete="off"
                   className="input"
                   id={this.searchFieldId}
                   onKeyDown={this.handleKeyDown}
                   onChange={this.handleChange}
                   ref={input => this.searchInput = input} />
          </div>

          <div className="filter-item">
            <label htmlFor={this.languageFieldId} className="label">
              {intl.formatMessage({id: 'questionSets.filter.languageField.label'})}
            </label>
            {this.renderLanguageSelect(this.languageFieldId)}
          </div>

          <div className="filter-item filter-reset">
            <a href="" onClick={this.handleReset}>
              <i className="fa fa-undo" />
              {intl.formatMessage({id: 'questionSets.filter.resetButton.label'})}
            </a>
          </div>
        </FormElement>
      </div>
    )
  }

  renderLanguageSelect(id) {
    const { languages, intl } = this.props
    const items = languages.map((language) => {
      return (
        <option key={language.id.toString()}
                value={language.id}>{language.name}</option>
      )
    })

    return (
      <select name="languageId"
              value={this.state.languageId}
              className="input"
              id={id}
              onChange={this.handleChange}>
        <option value="0">
          {intl.formatMessage({id: 'questionSets.filter.languageField.prompt'})}
        </option>
        {items}
      </select>
    )
  }
}

QuestionSetFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default injectIntl(QuestionSetFilter)
