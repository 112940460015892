import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import validator from 'validator'
import c from 'classnames'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import { formId } from '../helpers'
import { toNumber } from '../helpers/normalize'

const renderField = ({ input, label, type, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <input
        {...input}
        type={type}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const renderSelect = ({ input, label, items, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <select
        {...input}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      >
        {items}
      </select>
    </div>
  </div>
)

const renderLogoField = ({ input, label, meta, clientLogo, clientName, onLogoRemove, intl, id = formId() }) => {
  let { value: _, ...inputProps } = input
  return (
    <div>
      <div className="form-label">
        <label htmlFor={id}>{label}</label>
      </div>

      <div className="form-control" key={clientLogo}>
        <input
          {...inputProps}
          type="file"
          className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
          id={id}
        />

        {clientLogo &&
          <div className="logo-preview">
            <img src={clientLogo} alt={clientName} className="logo-preview-img" />
            <a href="#" onClick={onLogoRemove}>{intl.formatMessage({id: 'profile.logoField.removeLabel'})}</a>
          </div>
        }
      </div>
    </div>
  )
}

const ProfileForm = (
  {
    handleSubmit,
    submitting,
    onSubmit,
    onCancel,
    clientName,
    clientLogo,
    onLogoRemove,
    countries,
    intl,
  }) => {
  let countryOptions = [(<option key="0" value="">{intl.formatMessage({id: 'profile.countryField.prompt'})}</option>)]
  countryOptions.push(countries.map(country => (
    <option key={country.id.toString()} value={country.id}>{country.name}</option>
  )))

  return (
    <div className="inline-form">
      <FormElement onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.nameField.label'})}
              type="text"
              name="name"
              component={renderField}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.legalNameField.label'})}
              type="text"
              name="legalName"
              component={renderField}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.streetField.label'})}
              type="text"
              name="street"
              component={renderField}
            />
          </div>

          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.cityField.label'})}
              type="text"
              name="city"
              component={renderField}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.zipField.label'})}
              type="text"
              name="zip"
              component={renderField}
            />
          </div>

          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.countryField.label'})}
              name="countryId"
              component={renderSelect}
              items={countryOptions}
              normalize={toNumber}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.identityNumberField.label'})}
              type="text"
              name="ico"
              component={renderField}
            />
          </div>

          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.vatIdField.label'})}
              type="text"
              name="dic"
              component={renderField}
            />
          </div>
        </div>

        <div className="form-row form-row-logo-upload">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'profile.logoField.label'})}
              name="logo"
              clientName={clientName}
              clientLogo={clientLogo}
              onLogoRemove={onLogoRemove}
              intl={intl}
              component={renderLogoField}
            />
          </div>
        </div>

        <div className="form-line" />

        <div className="form-submit-row">
          <input
            type="submit"
            value={intl.formatMessage({id: 'profile.submitButton.label'})}
            className="button"
            disabled={submitting}
          />

          <span className="cancel">
            {intl.formatMessage({id: 'profile.cancelButton.conjunction'})}
            {' '}

            <a href="#"
               onClick={onCancel}>
              {intl.formatMessage({id: 'profile.cancelButton.label'})}
            </a>
          </span>
        </div>
      </FormElement>
    </div>
  )
}

const validate = values => {
  const errors = {}

  let fields = ['name', 'legalName', 'street', 'city', 'postCode']
  fields.forEach(name => {
    if (!values[name]) {
      errors[name] = 'Required'
    } else if (!validator.isLength(values[name], { max: 255 })) {
      errors[name] = 'Too long'
    }
  })

  // validate country
  if (!values.country) {
    errors.country = 'Required'
  }

  // validate identity number
  if (!values.identityNumber) {
    errors.identityNumber = 'Required'
  } else if (!validator.isLength(values.identityNumber, { max: 20 })) {
    errors.identityNumber = 'Too long'
  }

  // validate vat id
  if (values.vatId && !validator.isLength(values.vatId, { max: 20 })) {
    errors.vatId = 'Too long'
  }

  return errors
}

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  clientName: PropTypes.string.isRequired,
  clientLogo: PropTypes.string,
  onLogoRemove: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default injectIntl(reduxForm({
  form: 'profile',
  validate: validate,
})(ProfileForm))
