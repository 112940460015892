import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { loadQuestions, filterQuestions, createQuestion, removeQuestion } from '../actions/questions'
import { loadTags } from '../actions/tags'
import { filterQuestions as filterQuestionsSelector, getLanguageByCode } from '../selectors'
import QuestionList from '../components/QuestionList'
import QuestionFilter from '../components/QuestionFilter'
import QuestionForm from '../components/QuestionForm'
import Spinner from '../components/Spinner'

class Questions extends Component {

  constructor(props) {
    super()

    this.state = {
      createFormActive: false,
    }

    this.handleFilter = this.handleFilter.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.showCreateForm = this.showCreateForm.bind(this)
    this.hideCreateForm = this.hideCreateForm.bind(this)
    this.handleRemoveQuestion = this.handleRemoveQuestion.bind(this)

    props.dispatch(loadTags())
    props.dispatch(loadQuestions())
  }

  handleFilter(filterValue) {
    this.props.dispatch(filterQuestions(filterValue))
  }

  handleCreate(values, tags) {
    return this.props.dispatch(createQuestion(values, tags))
      .then(() => this.hideCreateForm())
  }

  showCreateForm(event) {
    if (event) event.preventDefault()
    this.setState({ createFormActive: true })
  }

  hideCreateForm(event) {
    if (event) event.preventDefault()
    this.setState({ createFormActive: false })
  }

  handleRemoveQuestion(event, question) {
    event.preventDefault()
    this.props.dispatch(removeQuestion(question.id))
  }

  render() {
    const {
      loaded,
      questions,
      languages,
      tags = [],
      match,
      intl,
      currentLanguage,
    } = this.props

    let actions = [
      {
        label: intl.formatMessage({id: 'questions.list.editAction.label'}),
        iconClassName: 'fa-pencil',
        link: (question) => {
          return `/library/question/${question.id}`
        }
      },
      {
        label: intl.formatMessage({id: 'questions.list.removeAction.label'}),
        iconClassName: 'fa-times',
        handle: this.handleRemoveQuestion,
      }
    ]

    return (
      <div>
        <div className="create-action">
          <a href="#"
             className="button create"
             id="question-list-create"
             onClick={this.showCreateForm}>
            <i className="fa fa-plus-circle"/>
            {intl.formatMessage({id: 'questions.addButton.label'})}
          </a>
        </div>

        {this.state.createFormActive &&
          <div className="inline-form"
               id="question-create-form">
            <QuestionForm
              form="questionCreate"
              onSubmit={this.handleCreate}
              onCancel={this.hideCreateForm}
              languages={languages}
              tagSuggestions={tags}
              initialValues={{
                languageId: currentLanguage.id,
                duration: 30,
              }}
            />
          </div>
        }

        <QuestionFilter
          onFilter={this.handleFilter}
          languages={languages}
          tags={tags}
        />

        {!loaded &&
          <Spinner />
        }

        {loaded &&
          <QuestionList
            languages={languages}
            questions={questions}
            actions={actions}
            editingId={match.params.id ? parseInt(match.params.id, 10) : null}
            form={this.props.questionForm}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.questions.loaded,
    questions: filterQuestionsSelector(state.questions.list, state.questions.filter),
    languages: state.languages.list,
    tags: state.tags.list,
    currentLanguage: getLanguageByCode(state.languages.list, state.currentLanguage),
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Questions)))
