import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { updateEmailTemplate } from '../actions/videoInterviews'
import Form from '../components/EmailTemplateInlineForm'

class VideoInterviewDetailsStepEmailForm extends Component {

  constructor() {
    super()

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    this.props.dispatch(updateEmailTemplate(this.props.emailTemplate, values))
      .then(() => {
        this.props.history.push('/video-interview/invite/details')
      })
  }

  render() {
    let { emailTemplate } = this.props

    return (
      <Form
        form={`emailTemplate-${emailTemplate.id}`}
        onSubmit={this.handleSubmit}
        onCancel="/video-interview/invite/details"
        initialValues={emailTemplate}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let emailTemplates = state.videoInterviewInvitation.emailTemplates

  return {
    emailTemplate: emailTemplates.find(template => template.id === parseInt(ownProps.match.params.id, 10)),
  }
}

export default withRouter(connect(mapStateToProps)(VideoInterviewDetailsStepEmailForm))
