import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import c from 'classnames'

const ItemListAction = ({ label, link, handle, iconClassName, item }) => {
  let url = '#'

  if (link && typeof link === 'function') {
    url = link(item)
  } else if (link && typeof link === 'string') {
    url = link
  }

  return (
    <li>
      <Link
        to={url}
        onClick={(event) => {
          if (handle) {
            handle(event, item)
          }
        }}
      >
        <i className={c('fa', iconClassName)} />
        {label}
      </Link>
    </li>
  )
}

ItemListAction.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  handle: PropTypes.func,
  iconClassName: PropTypes.string,
}

const ItemListActions = ({ actions, item }) => (
  <ul>
    {actions.map((action, i) => (
      <ItemListAction
        key={(i + 1).toString()}
        item={item}
        {...action}
      />
    ))}
  </ul>
)

ItemListActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      handle: PropTypes.func,
      iconClassName: PropTypes.string,
    })
  ).isRequired,
  item: PropTypes.object.isRequired,
}

export default ItemListActions
