import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { injectIntl, FormattedMessage } from 'react-intl'
import { credentialLetters, interviewStatusClass as statusClass } from '../helpers'
import InterviewStatus from './InterviewStatus'
import InterviewPlayer from './InterviewPlayer'
import FlashMessage from './FlashMessage'
import Note from './Note'
import NoteForm from './NoteForm'
import Spinner from '../components/Spinner'
import { DateTime } from './Date'

const InterviewContent = ({ interview, details, identity, onNoteSubmit, intl }) => {

  switch (interview.status) {
    case 'Pending':
      return (
        <InterviewStatus
          message={(
            <FormattedMessage
              id={`videoInterviews.interviewDetail.blankslate.${interview.status}`}
            />
          )}
        />
      )

    case 'Expired':
      return (
        <InterviewStatus
          message={(
            <FormattedMessage
              id={`videoInterviews.interviewDetail.blankslate.${interview.status}`}
              values={{
                expiryDate: (
                  <DateTime from={interview.expiryDate} />
                ),
              }}
            />
          )}
        />
      )

    case 'Declined':
      return (
        <InterviewStatus
          message={(
            <FormattedMessage
              id={`videoInterviews.interviewDetail.blankslate.${interview.status}`}
              values={{
                declineDate: (
                  <DateTime from={interview.declineDate} />
                ),
              }}
            />
          )}
        />
      )

    default:
      if (!details) {
        return (
          <div>Loading</div>
        )
      }

      let { answers, notes } = details
      const incomplete = answers.some(x => x.status !== 'Uploaded')

      return (
        <div>
          <div className={c('interview', { 'incomplete': incomplete })}>
            {incomplete &&
              <FlashMessage
                type="warning"
                message={intl.formatMessage({id: 'videoInterviews.interviewDetail.incompleteMessage'})}
              />
            }

            <InterviewPlayer
              interviewId={interview.id}
              answers={answers}
            />
          </div>

          <div className="comments">
            <h2 className="title">
              {intl.formatMessage({id: 'videoInterviews.interviewDetail.comments.title'})}
            </h2>

            {notes.map(note => (
              <Note key={note.id.toString()} {...note} />
            ))}

            <NoteForm
              onSubmit={values => onNoteSubmit(interview, values)}
              identity={identity}
            />
          </div>
        </div>
      )
  }
}

InterviewContent.propTypes = {
  interview: PropTypes.object.isRequired,
  details: PropTypes.object,
  identity: PropTypes.object.isRequired,
  onNoteSubmit: PropTypes.func.isRequired,
}

const TranslatedInterviewContent = injectIntl(InterviewContent)

const VideoInterviewDetail = ({ interview, details, identity, onNoteSubmit, intl }) => (
  <div className="video-interview-detail" id="video-interview-detail">
    <a
      href="#"
      className="button back-button"
      onClick={e => {
        e.preventDefault()
        window.history.back()
      }}
    >
      <i className="fa fa-angle-left" />
      {intl.formatMessage({id: 'videoInterviews.interviewDetail.backButton.label'})}
    </a>

    <div className="header item-list">
      <div className="column candidate-picture">
        <span>
          {credentialLetters(
            interview.candidate.firstName,
            interview.candidate.lastName
          )}
        </span>
      </div>

      <div className="column candidate-info">
        {interview.candidate.firstName + ' ' + interview.candidate.lastName}

        <span className="email">
          {interview.candidate.email}
          </span>
      </div>

      <div className="column job-position">
        {interview.jobPositinoLabel}
      </div>

      <div className="column status">
        <div className={c('label', statusClass(interview.status))}>
          {intl.formatMessage({id: `videoInterviews.status.${interview.status}`})}
        </div>
      </div>
    </div>

    <div className="line" />

    <div className="content">
      {!details &&
        <Spinner />
      }

      {details &&
        <TranslatedInterviewContent
          interview={interview}
          details={details}
          identity={identity}
          onNoteSubmit={onNoteSubmit}
        />
      }
    </div>
  </div>
)

VideoInterviewDetail.propTypes = {
  interview: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    candidate: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    jobPositinoLabel: PropTypes.string.isRequired,
  }).isRequired,
  details: PropTypes.shape({
    answers: PropTypes.array.isRequired,
    notes: PropTypes.array.isRequired,
  }),
  identity: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  onNoteSubmit: PropTypes.func.isRequired,
}

export default injectIntl(VideoInterviewDetail)
