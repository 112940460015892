import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import { Router, Route, Redirect } from 'react-router'
import { routerReducer } from 'react-router-redux'
import Raven from 'raven-js'
import { IntlProvider, intlReducer } from 'react-intl-redux'
import { createBrowserHistory } from "history"

import reducers from './reducers'
import { changeLanguage } from './actions/languages'
import { getIntl } from './helpers/localization'

import config from './config'
import App from './containers/App'
import VideoInterviews from './containers/VideoInterviews'
import VideoInterviewDetail from './containers/VideoInterviewDetail'
import VideoInterviewInvitation from './containers/VideoInterviewInvitation'
import VideoInterviewCandidatesStep from './containers/VideoInterviewCandidatesStep'
import VideoInterviewQuestionsStep from './containers/VideoInterviewQuestionsStep'
import VideoInterviewQuestionsStepQuestionForm from './containers/VideoInterviewQuestionsStepQuestionForm'
import VideoInterviewIntroductionVideoStep from './containers/VideoInterviewIntroductionVideoStep'
import VideoInterviewDetailsStep from './containers/VideoInterviewDetailsStep'
import VideoInterviewDetailsStepEmailForm from './containers/VideoInterviewDetailsStepEmailForm'
import VideoInterviewSummaryStep from './containers/VideoInterviewSummaryStep'
import Questions from './containers/Questions'
import QuestionForm from './containers/QuestionForm'
import Tags from './containers/Tags'
import TagForm from './containers/TagForm'
import QuestionSets from './containers/QuestionSets'
import QuestionSetsForm from './containers/QuestionSetsForm'
import Profile from './containers/Profile'
import Languages from './containers/Languages'
import LanguagesActivate from './containers/LanguagesActivate'
import EmailTemplates from './containers/EmailTemplatesContainer'
import EmailTemplateForm from './containers/EmailTemplateForm'

import LibraryNavigation from './components/LibraryNavigation'
import SettingsNavigation from './components/SettingsNavigation'
import { getQuery } from './helpers'

// setup raven client for logging to sentry
if (config.ravenDsn) {
  Raven.config(config.ravenDsn, {
    environment: config.environment,
    //release:,
    tags: {
      debug: config.debug,
    }
  }).install();
}

const composeEnhancers = (config.debug && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(
  combineReducers({
    ...reducers,
    routing: routerReducer,
    intl: intlReducer,
  }),
  composeEnhancers(applyMiddleware(thunk.withExtraArgument({intl: getIntl})))
)
const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider>
      <Router history={history}>
        <App>
          <div>
            <Route path="/" name={'primaryNavigation.home'}>
              <Route path="/video-interview" name={'videoInterviews.breadcrumbLabel'}>
                {/*todo: bread*/}
                <div className="content">
                  <Route path="/video-interview" exact={true} component={VideoInterviews} />
                  <Route path="/video-interview/detail/:id" component={VideoInterviewDetail} />
                  <Route path="/video-interview/invite">
                    <VideoInterviewInvitation>
                      <Route path="/video-interview/invite" exact={true} component={VideoInterviewCandidatesStep} />
                      <Route path="/video-interview/invite/questions/:id?">
                        <VideoInterviewQuestionsStep questionForm={<VideoInterviewQuestionsStepQuestionForm />} />
                      </Route>
                      <Route path="/video-interview/invite/introduction-video" component={VideoInterviewIntroductionVideoStep} />
                      <Route path="/video-interview/invite/details/:id?">
                        <VideoInterviewDetailsStep emailTemplateForm={<VideoInterviewDetailsStepEmailForm />} />
                      </Route>
                      <Route path="/video-interview/invite/summary" component={VideoInterviewSummaryStep} />
                    </VideoInterviewInvitation>
                  </Route>
                </div>
              </Route>
              <Route path="/library" name={'library.breadcrumbLabel'}>
                <LibraryNavigation />
                {/*todo: bread*/}
                <div className="content">
                  <Route path="/library/question/:id?" name={'questions.breadcrumbLabel'}>
                    <Questions questionForm={<QuestionForm />} />
                  </Route>
                  <Route path="/library/tag/:id?" name={'questionTags.breadcrumbLabel'}>
                    <Tags tagForm={<TagForm />} />
                  </Route>
                  <Route path="/library/question-set" name={'questionSets.breadcrumbLabel'}>
                    <div>
                      <Route path="/library/question-set" exact={true} component={QuestionSets} />
                      <Route path="/library/question-set/create" component={QuestionSetsForm} />
                      <Route path="/library/question-set/update/:id" component={QuestionSetsForm} />
                    </div>
                  </Route>
                </div>
              </Route>
              <Route path="/settings" name={'settings.breadcrumbLabel'}>
                <SettingsNavigation />
                {/*todo: bread*/}
                <div className="content">
                  <Route path="/settings/profile" component={Profile} name={'profile.breadcrumbLabel'} />
                  <Route path="/settings/language" name={'languages.breadcrumbLabel'}>
                    <div>
                      <Route path="/settings/language" exact={true} component={Languages} />
                      <Route path="/settings/language/activate" component={LanguagesActivate} name={'languages.activate.breadcrumbLabel'} />
                    </div>
                  </Route>
                  <Route path="/settings/email-template/:id?" name={'emailTemplates.breadcrumbLabel'}>
                    <EmailTemplates emailTemplateForm={<EmailTemplateForm />} />
                  </Route>
                </div>
              </Route>
            </Route>
          </div>
        </App>
      </Router>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)
