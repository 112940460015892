import * as actionType from '../constants/actionTypes'

const initialState = {
  loaded: false,
  list: [],
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.EMAIL_TEMPLATES_DATA_LOAD:
      return {
        ...state,
        loaded: true,
        list: action.templates,
      }

    default:
      return state
  }
}
