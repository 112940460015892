import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { formId } from '../helpers'

const EmailTemplatesFilter = ({ onChange, values, languages, intl }) => {
  const items = languages.map((language) => (
    <option key={language.id.toString()}
            value={language.id}>
      {language.name}
    </option>
  ))

  const languageFieldId = formId()

  return (
    <div className="filter">
      <div className="filter-item">
        <label htmlFor={languageFieldId} className="label">
          {intl.formatMessage({id: 'emailTemplates.filter.languageField.label'})}
        </label>

        <select name="languageId"
                value={values.languageId}
                id={languageFieldId}
                onChange={onChange}
                className="input">
          {items}
        </select>
      </div>
    </div>
  )
}

EmailTemplatesFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  values: PropTypes.shape({
    languageId: PropTypes.number.isRequired,
  }).isRequired,
}

export default injectIntl(EmailTemplatesFilter)
