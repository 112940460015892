import { IntlProvider } from 'react-intl'

let cachedIntl = null
let prevLocale = ''

export const getIntl = ({ intl: { locale, messages }}) => {
  if (!cachedIntl || locale !== prevLocale) {
    cachedIntl = (new IntlProvider({ locale, messages })).getChildContext().intl
    prevLocale = locale
  }

  return cachedIntl;
}
