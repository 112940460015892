import * as actionType from '../constants/actionTypes'

const initialState = {
  loaded: false,
  list: [],
  activated: [],
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.LANGUAGES_DATA_LOAD:
      return {
        ...state,
        loaded: true,
        list: action.languages,
        activated: action.clientLanguages,
      }

    case actionType.LANGUAGES_ACTIVATE:
      let languages = state.activated
      languages.push(action.language)

      return {
        ...state,
        activated: languages,
      }

    case actionType.LANGUAGES_DEACTIVATE:
      return {
        ...state,
        activated: state.activated.filter(language => language.id !== action.language.id)
      }

    case actionType.LANGUAGES_SET_DEFAULT:
      return {
        ...state,
        activated: state.activated.map((language) => {
          if (action.languageType === 'IsAppDefault') {
            language.isAppDefault = language.id === action.language.id
          } else if (action.languageType === 'IsRecorderDefault') {
            language.isRecorderDefault = language.id === action.language.id
          }

          return language
        }),
      }

    default:
      return state
  }
}
