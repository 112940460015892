import * as actionType from '../constants/actionTypes'
import { getEmailTemplates, putEmailTemplate } from './api'

export function loadEmailTemplatesFromApi(onUpdate) {
  return (dispatch, getState) => {
    getEmailTemplates().then((response) => {
      dispatch({
        type: actionType.EMAIL_TEMPLATES_DATA_LOAD,
        templates: response.data,
      })

      if (onUpdate) onUpdate()
    })
  }
}

export function reloadEmailTemplates() {
  return (dispatch, getState) => {
    return dispatch(loadEmailTemplatesFromApi())
  }
}

export function loadEmailTemplates() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().emailTemplates.loaded === true) {
      return
    }

    return dispatch(reloadEmailTemplates())
  }
}

export function updateEmailTemplate(template, values) {
  return (dispatch, getState) => {
    return putEmailTemplate(template.id, values).then(() => {
      dispatch(loadEmailTemplatesFromApi())
    })
  }
}
