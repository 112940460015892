import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import c from 'classnames'
import nl2br from 'react-nl2br'

class ConfirmDeleteDialog extends Component {

  constructor(props) {
    super()

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.closeOnOverlayClick = this.closeOnOverlayClick.bind(this)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(event) {
    if (event.keyCode === 27 && this.props.active) { // esc
      this.props.onCancel()
    }

    if (event.keyCode === 13 && this.props.active) { // enter
      this.props.onConfirm()
    }
  }

  closeOnOverlayClick(event) {
    this.props.onCancel()
  }

  render() {
    const { active, message, classNames, onConfirm, onCancel } = this.props

    if (!active) {
      return null
    }

    return (
      <div className={classNames.root}>
        <div className={classNames.overlay}
             onClick={this.closeOnOverlayClick}>
        </div>

        <div className={classNames.content}>
          <p className={classNames.message}>{nl2br(message)}</p>

          <ul className={classNames.actions}>
            <li className={c(classNames.actionItem, classNames.actionItemConfirm)}>
              <a href="#"
                 className={classNames.actionLink} onClick={e => {
                  e.preventDefault()
                  onConfirm()
                 }}
              >
                <FormattedMessage id={'removeDialog.confirmButton.label'} />
              </a>
            </li>

            <li className={c(classNames.actionItem, classNames.actionItemCancel)}>
              <a href="#"
                 className={classNames.actionLink} onClick={e => {
                  e.preventDefault()
                  onCancel()
                 }}
              >
                <FormattedMessage id={'removeDialog.cancelButton.label'} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

ConfirmDeleteDialog.propTypes = {
  active: PropTypes.bool,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  classNames: PropTypes.object,
}

ConfirmDeleteDialog.defaultProps = {
  active: false,
  message: 'Do you really want to delete this item?',
  classNames: {
    root: 'confirm-dialog',
    overlay: 'confirm-dialog-overlay',
    content: 'confirm-dialog-inner',
    message: 'confirm-dialog-message',
    actions: 'confirm-dialog-actions',
    actionItem: 'confirm-dialog-action-item',
    actionItemConfirm: 'confirm-dialog-action-confirm',
    actionItemCancel: 'confirm-dialog-action-cancel',
    actionLink: 'confirm-dialog-action-button',
  },
}

export default ConfirmDeleteDialog
