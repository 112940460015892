import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import c from 'classnames'
import Duration from './Duration'
import ItemList from './ItemList'

const AvailableQuestions = ({ languages, questions, onSelect, intl }) => (
  <ItemList
    items={questions}
    notFound="No questions found for this criteria."
    component={(item, { even, iterator }) => (
      <div key={iterator} className={c('item', {'item-even': even, 'item-odd': !even})}>
        <div className="column column-iterator">
          {iterator}
        </div>

        <div className="column">
          <span className="question-text alt">{item.text}</span>

          {' '}

          (
          <span className="question-duration">
            <Duration seconds={item.duration} />
          </span>
          ),

          {' '}

          <span className="question-language">{languages.find(x => x.id === item.languageId)?.name}</span>
        </div>

        <div className="column column-actions">
          <ul>
            <li>
              <a href="#" onClick={(e) => {
                e.preventDefault()
                onSelect(item)
              }}>
                <i className="fa fa-plus" />
                {intl.formatMessage({id: 'questionSets.addSet.availableQuestions.selectAction.label'})}
              </a>
            </li>
          </ul>
        </div>
      </div>
    )}
  />
)

AvailableQuestions.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      languageId: PropTypes.number.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default injectIntl(AvailableQuestions)
