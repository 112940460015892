import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import Favicon from 'react-favicon'
import { parseDomain } from 'parse-domain'
import AppComponent from '../components/App'
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog'
import Spinner from '../components/Spinner'
import { loadInitialData } from '../actions/init'
import { changeLanguage } from '../actions/languages'
import { initialDataLoaded, getLanguageByCode, getInterfaceLanguages } from '../selectors/index'
import { getQuery } from '../helpers'

class App extends Component {

  constructor(props) {
    super()

    this.minimized = getQuery(props.location.search).minimized === '1'
    props.dispatch(loadInitialData(props.location, props.history))
  }

  componentDidMount() {
    document.body.classList.toggle('override-minimized', this.minimized)
  }

  componentDidUpdate() {
    document.body.classList.toggle('override-minimized', this.minimized)
  }

  render() {
    const { loaded, confirmDialog, ...othersProps } = this.props

    if (!loaded) {
      return (
        <Spinner type="overlay" />
      )
    }

    return (
      <div>
        <Favicon url={this.props.instance.favIconUrl} />
        <AppComponent {...othersProps} />
        <ConfirmDeleteDialog active={confirmDialog.active}
                             message={confirmDialog.message}
                             onConfirm={this.props.confirmDialog.onConfirm}
                             onCancel={this.props.confirmDialog.onCancel} />
      </div>
    )
  }
}

App.propTypes = {
  loaded: PropTypes.bool.isRequired,
  confirmDialog: PropTypes.object.isRequired,
  identity: PropTypes.object,
}

const mapStateToProps = (state) => {
  let interfaceLanguages = getInterfaceLanguages(state.languages.list, state.languages.activated)

  return {
    loaded: initialDataLoaded(state),
    confirmDialog: state.confirmDeleteDialog,
    identity: state.identity,
    instance: state.instance,
    languages: interfaceLanguages,
    currentLanguage: getLanguageByCode(interfaceLanguages, state.currentLanguage),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: action => dispatch(action),
    onLanguageChange: (language) => dispatch(changeLanguage(language)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
