import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Link from './NavLink'

const StepForm = ({ steps, children, intl }) => (
  <div className="step-form">
    <div className="header">
      <ul>
        {steps.map((step, i) => (
          <Link
            key={(i + 1).toString()}
            to={step.link}
            className="step"
            onlyActiveOnIndex={step.indexLink || false}
          >
            <span>{i + 1}</span>
            {intl.formatMessage({id: step.title})}
          </Link>
        ))}
      </ul>
    </div>

    {children}
  </div>
)

StepForm.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      indexLink: PropTypes.bool,
    })
  ).isRequired,
}

export default injectIntl(StepForm)
