import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { loadEmailTemplates } from '../actions/emailTemplates'
import { filterEmailTemplatesByLanguage, getLanguageByCode } from '../selectors'
import EmailTemplates from '../components/EmailTemplates'
import EmailTemplatesFilter from '../components/EmailTemplatesFilter'
import Spinner from '../components/Spinner'

class EmailTemplatesContainer extends Component {

  constructor(props) {
    super()

    this.state = {
      languageId: props.currentLanguage.id,
    }

    this.handleFilter = this.handleFilter.bind(this)

    props.dispatch(loadEmailTemplates())
  }

  handleFilter(event) {
    this.setState({
      [event.target.name]: parseInt(event.target.value, 10),
    })
  }

  render() {
    const { loaded, match, languages, allLanguages } = this.props

    let templates = filterEmailTemplatesByLanguage(
      this.props.templates,
      this.state.languageId
    )

    return (
      <div>
        <EmailTemplatesFilter languages={languages}
                              values={this.state}
                              onChange={this.handleFilter} />

        {!loaded &&
          <Spinner />
        }

        {loaded &&
          <EmailTemplates
            languages={allLanguages}
            templates={templates}
            editingId={match.params.id ? parseInt(match.params.id, 10) : null}
            form={this.props.emailTemplateForm}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.emailTemplates.loaded,
    templates: state.emailTemplates.list,
    languages: state.languages.activated,
    allLanguages: state.languages.list,
    currentLanguage: getLanguageByCode(state.languages.list, state.currentLanguage),
  }
}

export default withRouter(connect(mapStateToProps)(EmailTemplatesContainer))
