import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import NotFoundListItem from './NotFoundListItem'

const amountClasses = [
  'cloud-size-0',
  'cloud-size-1',
  'cloud-size-2',
  'cloud-size-3',
  'cloud-size-4',
  'cloud-size-5',
]

let min = null
let max = null

const getAmountClass = function(tags, amount) {

  // iterate over tags and get min and max values
  if (!min && !max) {
    tags.map(tag => {
      min = Math.min(min, tag.questions.length)
      max = Math.max(max, tag.questions.length)

      return tag
    })
  }

  if (amount === 0) {
    return amountClasses[0]
  } else if (amount === max) {
    return amountClasses[amountClasses.length - 1]
  } else if (amount === min) {
    return amountClasses[1]
  }

  let part = (max - min) / (amountClasses.length - 1 - 2);
  let size = Math.round(amount / part);

  return amountClasses[size + 1]
}

const TagCloud = (
  {
    tags,
    filter,
    onDelete,
    editingId,
    form,
    intl,
  }) => (
  <div
    className="tag-cloud"
    id="tag-list"
  >
    {tags.map(tag => (
      <div
        key={tag.id.toString()}
        className={c({'tag-cloud-item': editingId !== tag.id}, getAmountClass(tags, tag.questions.length))}>
        <div className={c({'tag-cloud-item': editingId === tag.id}, getAmountClass(tags, tag.questions.length))}>
          <Link to={`/library/tag/${tag.id}`}>
            <span className="tag-attr-name">
              {tag.name}
            </span>
            &nbsp;
            (<span className="tag-attr-noq">{tag.questions.length}</span>)
          </Link>
        </div>

        {tag.id === editingId && form}
      </div>
    ))}

    {(tags.length === 0 && filter) &&
      <NotFoundListItem message={intl.formatMessage({id: 'questionTags.list.blankslate'})} />
    }
  </div>
)

TagCloud.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      questions: PropTypes.array.isRequired,
    })
  ).isRequired,
  filter: PropTypes.bool,
  editingId: PropTypes.number,
  form: PropTypes.element,
}

TagCloud.defaultProps = {
  filter: false,
}

export default injectIntl(TagCloud)
