import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import c from 'classnames'
import validator from 'validator'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import { formId } from '../helpers'

const renderField = ({ input, label, type, placeholder, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const renderTextArea = ({ input, label, placeholder, rows = 4, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <textarea
        {...input}
        rows={rows}
        placeholder={placeholder}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const EmailTemplateForm = (
  {
    handleSubmit,
    submitting,
    onSubmit,
    onCancel,
    intl,
  }) => (
  <div className="inline-form">
    <FormElement onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Field
          label={intl.formatMessage({id: 'emailTemplates.editTemplate.subjectField.label'})}
          type="text"
          name="subject"
          placeholder={intl.formatMessage({id: 'emailTemplates.editTemplate.subjectField.placeholder'})}
          component={renderField}
        />
      </div>

      <div className="form-row">
        <Field
          label={intl.formatMessage({id: 'emailTemplates.editTemplate.contentField.label'})}
          name="content"
          placeholder={intl.formatMessage({id: 'emailTemplates.editTemplate.contentField.placeholder'})}
          component={renderTextArea}
        />
      </div>

      <div className="form-submit-row">
        <input
          type="submit"
          value={intl.formatMessage({id: 'emailTemplates.editTemplate.submitButton.label'})}
          className="button"
          disabled={submitting}
        />

        <span className="cancel">
          {intl.formatMessage({id: 'emailTemplates.editTemplate.cancelButton.conjunction'})}
          {' '}

          <Link
            to={typeof onCancel === 'string' ? onCancel : '#'}
            onClick={typeof onCancel === 'function' ? onCancel : null}
          >
            {intl.formatMessage({id: 'emailTemplates.editTemplate.cancelButton.label'})}
          </Link>
        </span>
      </div>
    </FormElement>
  </div>
)

EmailTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
}

const validate = values => {
  const errors = {}

  // validate identity number
  if (!values.subject) {
    errors.subject = 'Required'
  } else if (!validator.isLength(values.subject, { max: 255 })) {
    errors.subject = 'Too long'
  }

  // validate vat id
  if (!values.content) {
    errors.content = 'Required'
  }

  return errors
}

export default injectIntl(reduxForm({
  form: 'emailTemplate',
  validate: validate,
})(EmailTemplateForm))
