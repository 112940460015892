import React from 'react'
import PropTypes from 'prop-types'

const FormElement = ({ children, ...props }) => {
  return (
    <form
      {...props}
      autoComplete="off"
      noValidate={true}>
      {children}
    </form>
  )
}

FormElement.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default FormElement