import { addLocaleData } from 'react-intl'
import { updateIntl } from 'react-intl-redux'
import * as actionTypes from '../constants/actionTypes'
import { getLanguages, getClientLanguages, createClientLanguage, removeClientLanguage, setLanguageDefault } from './api'
import { getTranslations } from './api'
import { getScript } from '../helpers'
import { reloadEmailTemplates } from './emailTemplates'

export function loadLanguages() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().languages.loaded) {
      return Promise.resolve()
    }

    // fetch data from api
    return Promise.all([
      getLanguages(),
      getClientLanguages()
    ]).then((response) => {
        dispatch({
          type: actionTypes.LANGUAGES_DATA_LOAD,
          languages: response[0].data,
          clientLanguages: response[1].data
        })
      })
  }
}

export function changeLanguage(locale) {
  return (dispatch, getState) => {
    if (getState().currentLanguage === locale) {
      return Promise.resolve()
    }

    return Promise.all([
      getScript(`https://unpkg.com/react-intl@2.9.0/locale-data/${locale}.js`),
      getTranslations(locale),
    ]).then((responses) => {
      try {
        localStorage.setItem("locale", locale)
      }
      catch {}

      addLocaleData(window.ReactIntlLocaleData[locale]);

      dispatch(updateIntl({
        locale,
        messages: responses[1].data,
      }))

      dispatch({
        type: actionTypes.CURRENT_LANGUAGE_CHANGE,
        language: locale,
      })

      //window.location.reload()
    })
  }
}

export function activate(languageId) {
  return (dispatch, getState) => {
    return createClientLanguage(languageId).then((response) => {
      const language = getState().languages.list.find(l => l.id === languageId)

      const clientLanguage = {
        id: languageId,
        name: language.name,
        code: language.code,
        isAppTranslated: language.isAppTranslated,
        isRecorderTranslated: language.isRecorderTranslated,
        isAppDefault: false,
        isRecorderDefault: false,
      }

      dispatch({
        type: actionTypes.LANGUAGES_ACTIVATE,
        language: clientLanguage,
      })

      dispatch(reloadEmailTemplates())
    })
  }
}

export function deactivate(language) {
  return (dispatch, getState, { intl }) => {
    const state = getState()

    dispatch({
      type: actionTypes.CONFIRM_DIALOG_SHOW,
      message: intl(state).formatMessage({id: 'languages.removeDialog.message'}),
      onCancel: () => {
        dispatch({
          type: actionTypes.CONFIRM_DIALOG_CANCEL,
        })
      },
      onConfirm: () => {
        removeClientLanguage(language.id).then(() => {
          dispatch({
            type: actionTypes.LANGUAGES_DEACTIVATE,
            language: language,
          })

          dispatch({
            type: actionTypes.CONFIRM_DIALOG_CONFIRM,
          })

          dispatch(reloadEmailTemplates())
        })
      },
    })
  }
}

export function setAsDefault(language, languageType) {
  return (dispatch, getState) => {
    setLanguageDefault(language.id, languageType).then(() => {
      dispatch({
        type: actionTypes.LANGUAGES_SET_DEFAULT,
        language: language,
        languageType: languageType,
      })
    })
  }
}
