import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { injectIntl, FormattedPlural } from 'react-intl'
import Duration from './Duration'
import ItemList from './ItemList'
import Actions from './ItemListActions'

const QuestionSetList = ({ languages, sets, actions, intl }) => (
  <ItemList
    items={sets}
    notFound={intl.formatMessage({id: 'questionSets.list.blankslate'})}
    component={(item, { even, iterator }) => (
      <div key={iterator} className={c('item', {'item-even': even, 'item-odd': !even})}>
        <div className="column column-iterator">
          {iterator}
        </div>

        <div className="column">
          <span className="item-attr-name alt">
            {item.name}
          </span>

          {' '}

          <span className="item-attr-questions-number">
            {item.questions.length}
            {' '}
            <FormattedPlural
              {...intl.messages['questionSets.list.questionsCounter']}
              value={item.questions.length}
            />
          </span>

          {' '}

          (<Duration seconds={item.totalDuration} className="item-attr-total-duration" />),

          {' '}

          <span className="item-attr-language">{languages.find(x => x.id === item.languageId)?.name}</span>
        </div>

        <div className="column column-actions">
          <Actions actions={actions} item={item} />
        </div>
      </div>
    )}
  />
)

QuestionSetList.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id:  PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      questions: PropTypes.array.isRequired,
      totalDuration: PropTypes.number.isRequired,
      languageId: PropTypes.number.isRequired,
    })
  ).isRequired,
  actions: PropTypes.array.isRequired,
}

export default injectIntl(QuestionSetList)
