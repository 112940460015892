import React from 'react'
import PropTypes from 'prop-types'

const User = ({ name, clientName }) => (
  <div className="user-area">
    <span className="username">{name}</span>
    <span className="company-name">{clientName}</span>
  </div>
)

User.propTypes = {
  name: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
}

export default User
