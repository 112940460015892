import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import FlashMessage from './FlashMessage'

const VideoInterviewIntroductionVideoStep = ({ nextStep, cancel, intl }) => (
  <div>
    <div className="content step-form-content">
      <FlashMessage message={intl.formatMessage({id: 'videoInterviews.invite.introductionVideo.featureNotAvailable'})} />
    </div>

    <div className="footer step-form-footer">
      <a href="#" className="button" onClick={nextStep}>
        {intl.formatMessage({id: 'videoInterviews.invite.nextStepButton.label'})}
      </a>

      <span className="cancel">
        {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.conjunction'})}
        {' '}

        <a href="#" onClick={cancel}>
          {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.label'})}
        </a>
      </span>
    </div>
  </div>
)

VideoInterviewIntroductionVideoStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default injectIntl(VideoInterviewIntroductionVideoStep)
