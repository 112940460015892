import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import { formId } from '../helpers'
import { toNumber } from '../helpers/normalize'

const renderSelect = ({ input, label, items, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <select
        {...input}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      >
        {items}
      </select>
    </div>
  </div>
)

const ActivateLanguageForm = (
  {
    handleSubmit,
    submitting,
    languages,
    onSubmit,
    onCancel,
    intl,
  }) => {
  let languageOptions = [(<option key="0" value="0">{intl.formatMessage({id: 'languages.activate.languageField.prompt'})}</option>)]
  languageOptions.push(languages.map(language => (
    <option key={language.id.toString()} value={language.id}>{language.name}</option>
  )))

  return (
    <div className="inline-form">
      <FormElement onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Field
            label={intl.formatMessage({id: 'languages.activate.languageField.label'})}
            name="languageId"
            component={renderSelect}
            items={languageOptions}
            normalize={toNumber}
          />
        </div>

        <div className="form-line"/>

        <div className="form-submit-row">
          <input
            type="submit"
            value={intl.formatMessage({id: 'languages.activate.submitButton.label'})}
            className="button"
            disabled={submitting}
          />

          <span className="cancel">
            {intl.formatMessage({id: 'languages.activate.cancelButton.conjunction'})}
            {' '}

            <Link
              to={typeof onCancel === 'string' ? onCancel : '#'}
              onClick={typeof onCancel === 'function' ? onCancel : null}
            >
              {intl.formatMessage({id: 'languages.activate.cancelButton.label'})}
            </Link>
          </span>
        </div>
      </FormElement>
    </div>
  )
}

ActivateLanguageForm.propTypes = {
  languages: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
}

const validate = values => {
  const errors = {}

  // validate identity number
  if (!values.languageId) {
    errors.languageId = 'Required'
  }

  return errors
}

export default injectIntl(reduxForm({
  form: 'activateLanguage',
  validate: validate,
})(ActivateLanguageForm))
