import React from 'react'
import PropTypes from 'prop-types'
import FormElement from './FormElement'

const LanguageSwitcher = ({ languages, current, onChange }) => {

  // do not render language switcher if there are less than 2 languages
  if (languages.length <= 1) {
    return (
      <div className="language-switcher" />
    )
  }

  const items = languages.map(language => (
    <option
      key={language.id.toString()}
      value={language.code}
    >
      {language.name}
    </option>
  ))

  return (
    <div className="language-switcher">
      <FormElement onSubmit={e => {
        e.preventDefault()
      }}>
        <select
          name="languageId"
          value={current.code}
          className="input"
          onChange={(e) => onChange(e.target.value)}
        >
          {items}
        </select>
      </FormElement>
    </div>
  )
}

LanguageSwitcher.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  current: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LanguageSwitcher
