import { SubmissionError } from 'redux-form'
import * as actionTypes from '../constants/actionTypes'
import { getSets, postSet, putSet, removeSet as removeSetApi } from './api'

export function loadSetsFromApi(onLoad) {
  return (dispatch, getState) => {
    getSets(getState().identity.clientId).then((response) => {
      dispatch({
        type: actionTypes.QUESTION_SETS_DATA_LOAD,
        sets: response.data,
      })

      if (onLoad) onLoad()
    })
  }
}

export function loadSets() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().sets.loaded === true) {
      return
    }

    return dispatch(loadSetsFromApi())
  }
}

export function filterSets(filters) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_SETS_FILTER,
      filters: filters,
    })
  }
}

export function createSet(values) {
  return (dispatch, getState) => {
    if (values.questions.length === 0) {
      throw new SubmissionError({ error: 'Please select at least one question.' })
    }

    return postSet(getState().identity.clientId, {
      name: values.name,
      languageId: parseInt(values.languageId, 10),
      questions: values.questions.map(q => ({id: q.id}))
    }).then(() => {
      dispatch(loadSetsFromApi())
    })
  }
}

export function updateSet(set, values) {
  return (dispatch, getState) => {
    if (values.questions.length === 0) {
      throw new SubmissionError({ error: 'Please select at least one question.' })
    }

    return putSet(getState().identity.clientId, set.id, {
      name: values.name,
      languageId: parseInt(values.languageId, 10),
      questions: values.questions.map(q => ({id: q.id}))
    }).then(() => {
      dispatch(loadSetsFromApi())
    })
  }
}

export function removeSet(id) {
  return (dispatch, getState, { intl }) => {
    const state = getState()

    dispatch({
      type: actionTypes.CONFIRM_DIALOG_SHOW,
      message: intl(state).formatMessage({id: 'questionSets.removeDialog.message'}),
      onCancel: () => {
        dispatch({
          type: actionTypes.CONFIRM_DIALOG_CANCEL,
        })
      },
      onConfirm: () => {
        removeSetApi(state.identity.clientId, id).then(() => {
          dispatch({
            type: actionTypes.CONFIRM_DIALOG_CONFIRM,
          })

          dispatch({
            type: actionTypes.QUESTION_SETS_REMOVE,
            id: id,
          })
        })
      },
    })
  }
}
