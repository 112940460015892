import React from 'react'
import { FormattedMessage } from 'react-intl'
import NavLink from './NavLink'

const SettingsNavigation = () => (
  <div className="hub-secondary-navigation">
    <ul>
      <NavLink to="/settings/profile"
               className="item">
        <i className="fa fa-info-circle" />
        <FormattedMessage id={'profile.navigationLabel'} />
      </NavLink>

      <NavLink to="/settings/language"
               className="item">
        <i className="fa fa-globe" />
        <FormattedMessage id={'languages.navigationLabel'} />
      </NavLink>

      <NavLink to="/settings/email-template"
               className="item">
        <i className="fa fa-envelope" />
        <FormattedMessage id={'emailTemplates.navigationLabel'} />
      </NavLink>
    </ul>
  </div>
)

export default SettingsNavigation
