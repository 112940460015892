import * as actionTypes from '../constants/actionTypes'
import { getQuestions, postQuestion, putQuestion, removeQuestion as removeQuestionApi } from './api'
import { deleteQuestionTag } from './api'
import { reloadTags } from './tags'

function loadQuestionsFromApi(onLoad) {
  return (dispatch, getState) => {
    getQuestions(getState().identity.clientId).then((response) => {
      dispatch({
        type: actionTypes.QUESTIONS_DATA_LOAD,
        questions: response.data,
      })

      if (onLoad) onLoad()
    })
  }
}

export function reloadQuestions() {
  return (dispatch, getState) => {
    return dispatch(loadQuestionsFromApi())
  }
}

export function loadQuestions() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().questions.loaded === true) {
      return
    }

    return dispatch(reloadQuestions())
  }
}

export function filterQuestions(filter) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTIONS_FILTER,
      filter: filter,
    })
  }
}

export function createQuestion(values, tags = []) {
  return (dispatch, getState) => {
    return postQuestion(getState().identity.clientId, {
      ...values,
      tags: tags.map(t => ({ id: t.id })),
    }).then(() => {
      dispatch(reloadQuestions())
      dispatch(reloadTags())
    })
  }
}

export function updateQuestion(question, values, tags = []) {
  return (dispatch, getState) => {
    return putQuestion(getState().identity.clientId, question.id, {
      ...values,
      tags: tags.map(t => ({ id: t.id })),
    }).then(() => {
      dispatch(reloadQuestions())
      dispatch(reloadTags())
    })
  }
}

export function removeQuestion(id) {
  return (dispatch, getState, { intl }) => {
    const state = getState()

    dispatch({
      type: actionTypes.CONFIRM_DIALOG_SHOW,
      message: intl(state).formatMessage({id: 'questions.removeDialog.message'}),
      onCancel: () => {
        dispatch({
          type: actionTypes.CONFIRM_DIALOG_CANCEL,
        })
      },
      onConfirm: () => {
        removeQuestionApi(state.identity.clientId, id).then(() => {
          dispatch({
            type: actionTypes.CONFIRM_DIALOG_CONFIRM,
          })

          dispatch({
            type: actionTypes.QUESTIONS_REMOVE,
            id: id,
          })

          dispatch(reloadTags())
        })
      },
    })
  }
}

export function removeQuestionTag(question, tag) {
  return (dispatch, getState) => {
    return deleteQuestionTag(getState().identity.clientId, question.id, tag.id).then(() => {
      return Promise.all([
        dispatch(reloadQuestions()),
        dispatch(reloadTags()),
      ])
    })
  }
}
