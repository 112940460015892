import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { formId } from '../helpers'

const VideoInterviewsFilter = ({ positions, values, onChange, onReset, intl }) => {
  const positionOptions = positions.map((position) => (
    <option
      key={position}
      value={position}
    >
      {position}
    </option>
  ))

  const searchFieldId = formId()
  const jobsFieldId = formId()

  return (
    <div className="filter">
      <div className="filter-item">
        <label htmlFor={searchFieldId} className="label">
          {intl.formatMessage({id: 'videoInterviews.filter.searchField.label'})}
        </label>

        <input
          type="text"
          name="search"
          value={values.search}
          id={searchFieldId}
          onChange={onChange}
          onKeyDown={(event) => {
            if (event.keyCode === 27) { // esc pressed
              onReset(event)
            }
          }}
          autoComplete="off"
          placeholder={intl.formatMessage({id: 'videoInterviews.filter.searchField.placeholder'})}
          className="input"
        />
      </div>

      <div className="filter-item">
        <label htmlFor={jobsFieldId} className="label">
          {intl.formatMessage({id: 'videoInterviews.filter.jobsField.label'})}
        </label>

        <select
          name="position"
          value={values.position}
          id={jobsFieldId}
          onChange={onChange}
          className="input"
        >
          <option value="">
            {intl.formatMessage({id: 'videoInterviews.filter.jobsField.prompt'})}
          </option>
          {positionOptions}
        </select>
      </div>

      <div className="filter-item filter-reset">
        <a href="#"
           onClick={onReset}>
          <i className="fa fa-undo" />
          {intl.formatMessage({id: 'videoInterviews.filter.resetButton.label'})}
        </a>
      </div>
    </div>
  )
}

VideoInterviewsFilter.propTypes = {
  positions: PropTypes.array.isRequired,
  values: PropTypes.shape({
    search: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

export default injectIntl(VideoInterviewsFilter)
