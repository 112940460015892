import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { injectIntl } from 'react-intl'
import Duration from './Duration'
import ItemList from './ItemList'
import Actions from './ItemListActions'

const Handle = SortableHandle(({ iterator }) => (
  <div className="column column-iterator">
    {iterator}
  </div>
))

const SortableItem = SortableElement(({ languages, item, actions, iterator, even, editingId, form }) => {
  let itemClassName = c('item', {'item-even': even, 'item-odd': !even})

  return (
    <div key={iterator} className={editingId !== item.id ? itemClassName : null}>
      <div className={editingId === item.id ? itemClassName : null}>
        <Handle iterator={iterator} />

        <div className="column">
          <span className="question-attr-text alt">{item.text}</span>
          {' '}
          (<Duration seconds={item.duration} className="question-attr-duration" />),
          {' '}
          <span className="question-attr-language">{languages.find(x => x.id === item.languageId)?.name}</span>
        </div>

        <div className="column column-actions">
          <Actions actions={actions} item={item} />
        </div>
      </div>

      {editingId === item.id &&
        <div className="inline-form">
          {form}
        </div>
      }
    </div>
  )
})

const SortableList = injectIntl(SortableContainer(({ questions, intl, ...otherProps }) => (
  <ItemList
    items={questions}
    notFound={intl.formatMessage({id: 'questions.list.blankslate'})}
    component={(item, itemProps) => (
      <SortableItem
        item={item}
        {...otherProps}
        {...itemProps}
        index={itemProps.index}
      />
    )}
  />
)))

const SortableQuestionList = ({ onSort, ...props }) => (
  <SortableList
    {...props}
    onSortEnd={onSort}
    useDragHandle={true}
    helperClass="sortable-item"
  />
)

SortableQuestionList.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      languageId: PropTypes.number,
    })
  ).isRequired,
  actions: PropTypes.array.isRequired,
  editingId: PropTypes.number,
  form: PropTypes.element,
  onSort: PropTypes.func.isRequired,
}

export default SortableQuestionList
