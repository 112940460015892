import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deactivate, setAsDefault } from '../actions/languages'
import ActivatedLanguages from '../components/ActivatedLanguages'

class Languages extends Component {

  constructor() {
    super()

    this.handleSetDefault = this.handleSetDefault.bind(this)
    this.handleDeactivate = this.handleDeactivate.bind(this)
  }

  handleSetDefault(event, language, type) {
    event.preventDefault()

    this.props.dispatch(setAsDefault(language, type))
  }

  handleDeactivate(event, language) {
    event.preventDefault()

    this.props.dispatch(deactivate(language))
  }

  render() {
    return (
      <ActivatedLanguages languages={this.props.languages}
                          onSetDefault={this.handleSetDefault}
                          onDeactivate={this.handleDeactivate} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    languages: state.languages.activated,
  }
}

export default connect(mapStateToProps)(Languages)
