import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import c from 'classnames'
import validator from 'validator'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import { formId } from '../helpers'

const questionItem = (question, onQuestionDelete) => (
  <li key={question.id.toString()}
      className="attached-question">
    <a href="#"
       onClick={(event) => onQuestionDelete(event, question)}>
      <i className="fa fa-times" />
    </a>
    {question.text}
  </li>
)

const renderQuestions = (questions, onQuestionDelete, intl) => (
  <div className="form-row tag-cloud-attached-questions">
    <h3 className="title">{intl.formatMessage({id: 'questionTags.editTag.questionList.title'})}</h3>
    <ul className="attached-questions-list">
      {questions.map((question) => questionItem(question, onQuestionDelete))}
    </ul>
  </div>
)

const renderField = ({ input, label, type = "text", meta, className, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <input
        {...input}
        type={type}
        className={c(className, {'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const TagForm = (
  {
    handleSubmit,
    submitting,
    onSubmit,
    onCancel,
    questions = [],
    onQuestionDelete = () => {},
    intl,
  }) => (
  <FormElement onSubmit={handleSubmit(onSubmit)}>
    <div className="form-row">
      <Field
        label={intl.formatMessage({id: 'questionTags.addTag.nameField.label'})}
        name="name"
        className="w50"
        component={renderField}
      />
    </div>

    {questions.length > 0 && renderQuestions(questions, onQuestionDelete, intl)}

    <div className="form-submit-row">
      <input
        type="submit"
        value={intl.formatMessage({id: 'questionTags.addTag.submitButton.label'})}
        className="button"
        disabled={submitting}
      />

      <span className="cancel">
        {intl.formatMessage({id: 'questionTags.addTag.cancelButton.conjunction'})}
        {' '}

        <Link
          to={typeof onCancel === 'string' ? onCancel : '#'}
          onClick={typeof onCancel === 'function' ? onCancel : null}
        >
          {intl.formatMessage({id: 'questionTags.addTag.cancelButton.label'})}
        </Link>
      </span>
    </div>
  </FormElement>
)

TagForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  onQuestionDelete: PropTypes.func,
}

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  } else if (!validator.isLength(values.name, { max: 255 })) {
    errors.name = 'Too long'
  }

  return errors
}

export default injectIntl(reduxForm({
  form: 'tag',
  validate: validate,
})(TagForm))
