import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

const FlashMessage = ({ message, type, button }) => (
  <div className={c('flash-message', `flash-message-${type}`)}>
    <p>{message}</p>
    {button &&
      <p>{button}</p>
    }
  </div>
)

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['warning', 'info', 'error', 'success']),
  button: PropTypes.element,
}

FlashMessage.defaultProps = {
  type: 'info',
}

export default FlashMessage
