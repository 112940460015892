import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { activate } from '../actions/languages'
import ActivateLanguageForm from '../components/ActivateLanguageForm'
import { getInactiveLanguages } from '../selectors'

class ActivateLanguageFormContainer extends Component {

  render() {
    const { languages, onSubmit } = this.props

    return (
      <ActivateLanguageForm
        languages={languages}
        onSubmit={onSubmit}
        onCancel="/settings/language"
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    languages: getInactiveLanguages(state.languages.list, state.languages.activated),
  }
}

const dispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: action => dispatch(action),
    onSubmit: values => {
      return dispatch(activate(values.languageId))
        .then(() => {
          ownProps.history.push('/settings/language')
        })
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  dispatchToProps
)(ActivateLanguageFormContainer))
