import * as actionType from '../constants/actionTypes'

const initialState = null

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.INSTANCE_DATA_LOAD:
      return {
        ...action.instance,
      }
      
    default:
      return state
  }
}
