import {
  getVideoInterviews,
  getVideoInterview,
  postVideoInterviewNote as postNote,
  postVideoInterviewDefinition,
  postVideoInterviewDefinitionQuestion,
  postVideoInterview,
} from './api'
import { getSet } from './api'
import * as actionType from '../constants/actionTypes'
import { convertToMp4 } from './browserSpecific'

export function loadInterviewsFromApi() {
  return (dispatch, getState) => {
    return getVideoInterviews(getState().identity.clientId).then((response) => {
      dispatch({
        type: actionType.VIDEO_INTERVIEWS_DATA_LOAD,
        interviews: response.data,
      })
    })
  }
}

export function reloadInterviews() {
  return (dispatch, getState) => {
    return dispatch(loadInterviewsFromApi())
  }
}

export function loadInterviews() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().videoInterviews.loaded === true) {
      return Promise.resolve()
    }

    return dispatch(reloadInterviews())
  }
}

export function loadInterviewDetailFromApi(id) {
  return (dispatch, getState) => {
    return getVideoInterview(getState().identity.clientId, id, convertToMp4).then((response) => {
      dispatch({
        type: actionType.VIDEO_INTERVIEWS_DETAILS_LOAD,
        id: id,
        details: response.data,
      })
    })
  }
}

export function loadInterviewDetail(id) {
  return (dispatch, getState) => {
    return Promise.all([
      dispatch(loadInterviewDetailFromApi(id)),
      dispatch(loadInterviews()), // we also need interviews list
    ])
  }
}

export function createNote(interview, noteData) {
  return (dispatch, getState) => {
    return postNote(getState().identity.clientId, interview.id, noteData).then(() => {
      return dispatch(loadInterviewDetail(interview.id))
    })
  }
}

export function selectSet(set) {
  return (dispatch, getState) => {
    return getSet(getState().identity.clientId, set.id).then((response) => {
      let set = response.data

      dispatch({
        type: actionType.VIDEO_INTERVIEWS_SELECT_SET,
        set: set,
        questions: set.questions.map((q, i) => {
          return {
            ...q,
            id: i + 1,
          }
        }),
      })
    })
  }
}

export function createQuestion(values) {
  return (dispatch, getState) => {
    let questionId = () => {
      let max = 0
      let questions = getState().videoInterviewInvitation.questions

      questions.map(q => {
        max = Math.max(max, q.id)
        return q
      })

      return max + 1
    }

    dispatch({
      type: actionType.VIDEO_INTERVIEWS_CREATE_QUESTION,
      values: {
        id: questionId(),
        text: values.text,
        duration: parseInt(values.duration, 10),
        languageId: values.languageId,
      },
    })
  }
}

export function updateQuestion(question, values) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_UPDATE_QUESTION,
      question: question,
      values: {
        text: values.text,
        duration: parseInt(values.duration, 10),
        languageId: values.languageId,
      },
    })

    return Promise.resolve()
  }
}

export function removeQuestion(question) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_REMOVE_QUESTION,
      question: question,
    })
  }
}

export function updateDefinitionLanguage(languageId) {
  return (dispatch, getState) => {
    let language = getState().languages.list.find(l => l.id === languageId)

    dispatch({
      type: actionType.VIDEO_INTERVIEWS_DEFINITION_LANGUAGE,
      language: language,
      templates: getState().emailTemplates.list.filter(template => template.languageId === language.id),
    })

    return Promise.resolve()
  }
}

export function updateDefinitionExpiry(expiryDays) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_DEFINITION_EXPIRY,
      expiryDays: expiryDays,
    })

    return Promise.resolve()
  }
}

export function updateDefinitionReminder(reminderDays) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_DEFINITION_REMINDER,
      reminderDays: reminderDays,
    })

    return Promise.resolve()
  }
}

export function updateEmailTemplate(emailTemplate, values) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_EMAIL_TEMPLATE_UPDATE,
      emailTemplate: emailTemplate,
      values: values,
    })

    return Promise.resolve()
  }
}

export function invite() {
  return async (dispatch, getState) => {
    const state = getState()
    let data = state.videoInterviewInvitation
    const clientId = state.identity.clientId

    // validate
    if (data.candidates.length === 0) {
      throw new Error('You have to select at least one candidate.')
    } else if (data.questions.length === 0) {
      throw new Error('You have to select at least one question.')
    } else if (data.emailTemplates.length === 0) {
      throw new Error('You have to set the email templates.')
    }

    const interviewDefinition = await postVideoInterviewDefinition(clientId, { languageId: data.languageId })
    const interviewDefinitionId = interviewDefinition.data.id

    for (let i = 0; i < data.questions.length; i++) {
      const question = data.questions[i]
      await postVideoInterviewDefinitionQuestion(clientId, interviewDefinitionId, {
        videoInterviewId: interviewDefinitionId,
        languageId: question.languageId,
        text: question.text,
        duration: question.duration,
        position: i + 1,
      })
    }

    for (let i = 0; i < data.candidates.length; i++) {
      const candidate = data.candidates[i]
      await postVideoInterview(clientId, {
        definitionId: interviewDefinitionId,
        candidateId: candidate.id,
        expiryDays: data.expiryDays,
        reminderDays: data.reminderDays,
        jobPositionLabel: data.jobPosition,
        userId: state.identity.id,
      })
    }

    dispatch(reloadInterviews())
  }
}

export function removeCandidate(candidate) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_REMOVE_CANDIDATE,
      candidate: candidate,
    })
  }
}

export function sortQuestions(oldIndex, newIndex) {
  return (dispatch, getState) => {
    dispatch({
      type: actionType.VIDEO_INTERVIEWS_SORT_QUESTIONS,
      oldIndex,
      newIndex,
    })
  }
}
