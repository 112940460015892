import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { updateQuestion } from '../actions/questions'
import { loadTags } from '../actions/tags'
import Form from '../components/QuestionForm'

class QuestionForm extends Component {

  constructor(props) {
    super()

    props.dispatch(loadTags())
  }

  render() {
    const { languages, question, onSubmit, tags } = this.props

    return (
      <Form
        form={`question-${question.id}`}
        onSubmit={(values, tags) => onSubmit(question, values, tags)}
        onCancel="/library/question"
        languages={languages}
        tagSuggestions={tags}
        initialValues={{
          ...question,
          languageId: question.languageId,
          tags: question.tags.map(tag => {
            return { id: tag.id, name: tag.name }
          }),
        }}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    question: state.questions.list.find(question => question.id === parseInt(ownProps.match.params.id, 10)),
    languages: state.languages.list,
    tags: state.tags.list,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: action => dispatch(action),
    onSubmit: (question, values, tags) => {
      return dispatch(updateQuestion(question, values, tags))
        .then(() => {
          ownProps.history.push('/library/question')
        })
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionForm))
