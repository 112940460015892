import * as actionType from '../constants/actionTypes'
import arrayMove from 'array-move'

const initialState = {
  candidates: [],
  jobPosition: null,
  questions: [],
  languageId: 0,
  expiryDays: 14,
  reminderDays: 2,
  emailTemplates: [],
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.IDENTITY_DATA_LOAD:
      return {
        ...state,
        candidates: action.identity.candidates,
        jobPosition: action.identity.jobPosition,
      }

    case actionType.VIDEO_INTERVIEWS_REMOVE_CANDIDATE:
      return {
        ...state,
        candidates: state.candidates.filter(c => c.id !== action.candidate.id),
      }

    case actionType.VIDEO_INTERVIEWS_SELECT_SET:
      return {
        ...state,
        questions: action.questions,
      }

    case actionType.VIDEO_INTERVIEWS_CREATE_QUESTION:
      let questions = state.questions.concat()
      questions.push(action.values)

      return {
        ...state,
        questions: questions,
      }

    case actionType.VIDEO_INTERVIEWS_REMOVE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter(q => q.id !== action.question.id),
      }

    case actionType.VIDEO_INTERVIEWS_UPDATE_QUESTION:
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question.id === action.question.id) {
            question = {
              ...question,
              ...action.values,
            }
          }

          return question
        }),
      }

    case actionType.VIDEO_INTERVIEWS_SORT_QUESTIONS:
      return {
        ...state,
        questions: arrayMove(state.questions, action.oldIndex, action.newIndex),
      }

    case actionType.VIDEO_INTERVIEWS_DEFINITION_LANGUAGE:
      return {
        ...state,
        languageId: action.language.id,
        emailTemplates: action.templates,
      }

    case actionType.VIDEO_INTERVIEWS_DEFINITION_EXPIRY:
      return {
        ...state,
        expiryDays: action.expiryDays,
      }

    case actionType.VIDEO_INTERVIEWS_DEFINITION_REMINDER:
      return {
        ...state,
        reminderDays: action.reminderDays,
      }

    case actionType.EMAIL_TEMPLATES_DATA_LOAD:
      return {
        ...state,
        emailTemplates: action.templates.filter(template => template.languageId === state.languageId),
      }

    case actionType.VIDEO_INTERVIEWS_EMAIL_TEMPLATE_UPDATE:
      return {
        ...state,
        emailTemplates: state.emailTemplates.map(template => {
          if (template.id === action.emailTemplate.id) {
            template = {
              ...template,
              ...action.values,
            }
          }

          return template
        })
      }

    default:
      return state
  }
}
