import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import FlashMessage from '../components/FlashMessage'
import LanguageFormContainer from './ActivateLanguageFormContainer'
import { getInactiveLanguages } from '../selectors'

class LanguagesActivate extends Component {

  render() {
    const { languages, intl } = this.props

    if (languages.length === 0) {
      return (
        <FlashMessage
          type="warning"
          message={intl.formatMessage({id: 'languages.activate.blankslate.message'})}
          button={
            <Link to="/settings/language" className="button">
              {intl.formatMessage({id: 'languages.activate.blankslate.backButton.label'})}
            </Link>
          }
        />
      )
    }

    return (
      <LanguageFormContainer />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    languages: getInactiveLanguages(state.languages.list, state.languages.activated),
  }
}

export default injectIntl(connect(mapStateToProps)(LanguagesActivate))
