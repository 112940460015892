import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import styles from './InterviewPlayer.css' // eslint-disable-line no-unused-vars
import c from 'classnames'
import Duration from './Duration'
import { convertToMp4 } from '../actions/browserSpecific'
import { loadInterviewDetail } from '../actions/videoInterviews'
import Spinner from './Spinner'

class InterviewPlayer extends Component {

  constructor(props) {
    super(props)

    const index = this.findNextAnswer(-1)

    this.state = {
      activeAnswerIndex: index,
      autoPlay: false,
    }

    this.handlePlaybackStop = this.handlePlaybackStop.bind(this)
  }

  componentDidMount() {
    this.checkConvertToMp4()
  }

  componentWillUnmount() {
    this.checkTimer && clearInterval(this.checkTimer)
  }

  checkConvertToMp4 = () => {
    this.checkTimer = null

    if (!convertToMp4) {
      return
    }

    const someRunning = this.props.answers.some(x => x.mp4State === 'Running')
    if (!someRunning) {
      return
    }

    this.props.dispatch(loadInterviewDetail(this.props.interviewId))

    this.checkTimer = setTimeout(this.checkConvertToMp4, 3000)
  }

  findNextAnswer(prevIndex) {
    const { answers } = this.props;
    for (let i = prevIndex+1; i < answers.length; i++) {
      const answer = answers[i];
      if (answer.status === 'Uploaded') {
        return i;
      }
    }

    return -1;
  }

  playAnswer(answerIndex, autoPlay) {
    this.setState({
      activeAnswerIndex: answerIndex,
      autoPlay: autoPlay,
    });
  }

  handleAnswerClick(answer, event) {
    event.preventDefault()
    const index = this.props.answers.indexOf(answer)
    this.playAnswer(index, true)
  }

  handlePlaybackStop() {
    var index = this.findNextAnswer(this.state.activeAnswerIndex)
    if (index === -1) {
      index = this.findNextAnswer(-1)
      this.playAnswer(index, false)
    } else {
      this.playAnswer(index, true)
    }
  }

  render() {
    const { answers } = this.props
    const { activeAnswerIndex, autoPlay } = this.state
    const activeAnswer = answers[activeAnswerIndex]

    var url = activeAnswer.url
    if (convertToMp4 && activeAnswer.mp4State === "Suceeded") {
      url = url.split(".webm")[0] + ".mp4?alt=media"
    }

    const videoType = url.includes(".mp4") ? "video/mp4" : "video/webm"

    return (
      <div>
        <div className="player">
          {
            (convertToMp4 && activeAnswer.mp4State === "Running") ?
              <Spinner type="overlay" />
            :
              <Video key={activeAnswer.id}
                onEnded={this.handlePlaybackStop}
                autoPlay={autoPlay}
              >
                <source src={url} type={videoType} />
              </Video>
          }
        </div>

        <div className="questions">
          <ul>
            {answers.map(answer => (
              <li
                key={answer.id.toString()}
                className={c('questions-item', {
                  'active': activeAnswer && activeAnswer.id === answer.id,
                  'questions-item-invalid': answer.status !== 'Uploaded',
                })}
                onClick={this.handleAnswerClick.bind(this, answer)}
              >
                {answer.questionText}
                {' '}

                <span className="duration">
                  (<Duration seconds={answer.questionDuration} />)
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

InterviewPlayer.propTypes = {
  interviewId: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      questionText: PropTypes.string.isRequired,
      questionDuration: PropTypes.number.isRequired,
      mp4State: PropTypes.string.isRequired,
    })
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(InterviewPlayer)