import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { loadTags, filterTags, createTag } from '../actions/tags'
import { loadQuestions } from '../actions/questions'
import { filterTags as filterTagsSelector } from '../selectors'
import TagFilter from '../components/TagFilter'
import TagCloud from '../components/TagCloud'
import TagForm from '../components/TagForm'
import Spinner from '../components/Spinner'

class Tags extends Component {

  constructor() {
    super()

    this.state = {
      createFormActive: false,
    }

    this.handleFilter = this.handleFilter.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.showCreateForm = this.showCreateForm.bind(this)
    this.hideCreateForm = this.hideCreateForm.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(loadTags())
    this.props.dispatch(loadQuestions())
  }

  handleFilter(filterValue) {
    this.props.dispatch(filterTags(filterValue))
  }

  handleCreate(values) {
    return this.props.dispatch(createTag(values))
      .then(() => this.hideCreateForm())
  }

  showCreateForm(event) {
    if (event) event.preventDefault()
    this.setState({ createFormActive: true })
  }

  hideCreateForm(event) {
    if (event) event.preventDefault()

    this.setState({
      createFormActive: false,
    })
  }

  render() {
    const { loaded, tags, filterActive, match, intl } = this.props

    return (
      <div>
        <div className="create-action">
          <a href="#"
             className="button create"
             id="tag-list-create"
             onClick={this.showCreateForm}>
            <i className="fa fa-plus-circle" />
            {intl.formatMessage({id: 'questionTags.addButton.label'})}
          </a>
        </div>

        {this.state.createFormActive &&
          <div className="inline-form" id="tag-create-form">
            <TagForm
              form="tagCreate"
              onSubmit={this.handleCreate}
              onCancel={this.hideCreateForm}
            />
          </div>
        }

        <TagFilter onFilter={this.handleFilter} />

        {!loaded &&
          <Spinner />
        }

        {loaded &&
          <TagCloud
            tags={tags}
            filter={filterActive}
            editingId={match.params.id ? parseInt(match.params.id, 10) : null}
            form={this.props.tagForm}
          />
        }
      </div>
    )
  }
}

Tags.propTypes = {
  loaded: PropTypes.bool,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      questions: PropTypes.array.isRequired,
    })
  ),
  filterActive: PropTypes.bool,
}

Tags.defaultProps = {
  loaded: false,
  filterActive: false,
}

let mapStateToProps = (state) => {
  return {
    loaded: state.tags.loaded && state.questions.loaded,
    tags: filterTagsSelector(state.tags.list, state.tags.filter),
    filterActive: !!state.tags.filter,
    questions: state.questions.list,
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Tags)))
