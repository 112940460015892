import React from 'react'
import PropTypes from 'prop-types'
import SpinnerEl from 'react-spinner'
import c from 'classnames'
import styles from './Spinner.css' // eslint-disable-line no-unused-vars

const containerStyles = {
  item: {
    classes: [],
    styles: {
      height: 80,
    },
  },
  overlay: {
    classes: ['mod-big'],
    styles: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
}

const Spinner = ({ type = 'item' }) => (
  <div style={containerStyles[type]['styles']}>
    <SpinnerEl className={c(containerStyles[type]['classes'])} />
  </div>
)

Spinner.propTypes = {
  type: PropTypes.oneOf([
    'item',
    'overlay',
  ]),
}

export default Spinner
