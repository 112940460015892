import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import ReactTags from 'react-tag-autocomplete'
import { formId } from '../helpers'

class QuestionFilter extends Component {

  constructor(props) {
    super()

    this.state = {
      tags: [],
    }
    this.focus = this.focus.bind(this)
    this.reset = this.reset.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleLanguageFilter = this.handleLanguageFilter.bind(this)
    this.handleAddTag = this.handleAddTag.bind(this)
    this.handleDeleteTag = this.handleDeleteTag.bind(this)

    this.searchFieldId = formId()
    this.tgsFieldId = formId()
    this.languageFieldId = formId()
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  focus() {
    this.searchInput.focus()
  }

  reset() {
    this.searchInput.value = ''
    this.languageInput.value = ''
    this.setState({
      tags: [],
    })

    this.props.onFilter({
      search: null,
      languageId: null,
      tags: [],
    })
  }

  handleSearch(event) {
    const { onFilter } = this.props

    if (event.keyCode === 27) { // esc pressed
      this.reset()
    } else {
      onFilter({ search: this.searchInput.value !== '' ? this.searchInput.value : null })
    }
  }

  handleLanguageFilter() {
    const { onFilter } = this.props
    onFilter({ languageId: parseInt(this.languageInput.value, 10) })
  }

  handleAddTag(tag) {
    let tags = this.state.tags.concat(tag)

    this.setState({ tags: tags })
    this.props.onFilter({ tags: tags })
  }

  handleDeleteTag(i) {
    let tags = this.state.tags.slice(0)
    tags.splice(i, 1)

    this.setState({ tags: tags })
    this.props.onFilter({ tags: tags })
  }

  render() {
    const { intl } = this.props

    return (
      <div className="filter" id="question-list-filter">
        <FormElement>
          <div className="filter-row">
            <label htmlFor={this.searchFieldId} className="label">
              {intl.formatMessage({id: 'questions.filter.searchField.label'})}
            </label>

            <div className="form-control">
              <input type="text"
                     autoComplete="off"
                     placeholder={intl.formatMessage({id: 'questions.filter.searchField.placeholder'})}
                     className="input"
                     id={this.searchFieldId}
                     ref={(ref) => this.searchInput = ref}
                     onKeyUp={this.handleSearch} />
            </div>
          </div>

          <div className="filter-row">
            <label className="label">
              {intl.formatMessage({id: 'questions.filter.tagsField.label'})}
            </label>

            <div className="form-control">
              <ReactTags tags={this.state.tags}
                         placeholder={intl.formatMessage({id: 'questions.filter.tagsField.placeholder'})}
                         suggestions={this.props.tags}
                         onDelete={this.handleDeleteTag}
                         onAddition={this.handleAddTag}
                         allowNew={false}
                         autoresize={false}
                         autofocus={false}
                         minQueryLength={1} />
            </div>
          </div>

          <div className="filter-row">
            <label htmlFor={this.languageFieldId} className="label">
              {intl.formatMessage({id: 'questions.filter.languageField.label'})}
            </label>

            <div className="form-control">
              {this.renderLanguageSelect(this.languageFieldId)}
            </div>
          </div>

          <div className="filter-item filter-reset">
            <a href=""
              onClick={(event) => {
                event.preventDefault()
                this.reset()
              }}>
              <i className="fa fa-undo"/>
              {intl.formatMessage({id: 'questions.filter.resetButton.label'})}
            </a>
          </div>
        </FormElement>
      </div>
    )
  }

  renderLanguageSelect(id) {
    const { languages, intl } = this.props
    const items = languages.map((language) => {
      return (
        <option key={language.id.toString()}
                value={language.id}>{language.name}</option>
      )
    })

    return (
      <select className="input"
              id={id}
              onChange={this.handleLanguageFilter}
              ref={(ref) => this.languageInput = ref}>
        <option value="">
          {intl.formatMessage({id: 'questions.filter.languageField.prompt'})}
        </option>
        {items}
      </select>
    )
  }
}

QuestionFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  autoFocus: PropTypes.bool,
}

QuestionFilter.defaultProps = {
  autoFocus: true,
}

export default injectIntl(QuestionFilter)
