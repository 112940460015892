import React from 'react'
import PropTypes from 'prop-types'
import * as moment from 'moment'
import momentDuration from 'moment-duration-format' // eslint-disable-line no-unused-vars

const Duration = ({ seconds, className }) => (
  <span className={className}>
    {moment.duration(seconds, 'seconds').format({
      template: 'mm:ss',
      trim: false,
    })}
  </span>
)

Duration.propTypes = {
  seconds: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default Duration
