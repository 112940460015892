import React from 'react'
import PropTypes from 'prop-types'

const InterviewStatus = ({ message }) => (
  <div className="interview-status">
    <p>{message}</p>
  </div>
)

InterviewStatus.propTypes = {
  message: PropTypes.node.isRequired,
}

export default InterviewStatus
