import { reducer as formReducer } from 'redux-form'

import identity from './identity'
import instance from './instance'
import questions from './questions'
import languages from './languages'
import countries from './countries'
import tags from './tags'
import confirmDeleteDialog from './confirmDeleteDialog'
import sets from './sets'
import currentLanguage from './currentLanguage'
import emailTemplates from './emailTemplates'
import videoInterviews from './videoInterviews'
import videoInterviewInvitation from './videoInterviewInvitation'
import jobPositions from './jobPositions'

const reducers = {
  form: formReducer,
  instance,
  identity,
  questions,
  languages,
  countries,
  tags,
  confirmDeleteDialog,
  sets,
  currentLanguage,
  emailTemplates,
  videoInterviews,
  videoInterviewInvitation,
  jobPositions,
}

export default reducers
