import * as actionType from '../constants/actionTypes'

const initialState = {
  loaded: false,
  list: [],
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.COUNTRIES_DATA_LOAD:
      return {
        ...state,
        loaded: true,
        list: action.countries,
      }

    default:
      return state
  }
}
