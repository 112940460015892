import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { invite } from '../actions/videoInterviews'
import Summary from '../components/VideoInterviewSummaryStep'
import { getInvitationLanguage } from '../selectors'

class VideoInterviewSummaryStep extends Component {

  constructor() {
    super()

    this.state = {
      submitting: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()

    try {
      this.props.dispatch(invite())
        .then(() => {
          this.setState({
            submitting: false,
          })

          this.props.history.push('/video-interview')
        })

      this.setState({
        submitting: true,
      })
    } catch (e) {}
  }

  render() {
    return (
      <Summary
        {...this.props}
        submit={this.handleSubmit}
        submitting={this.state.submitting}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let languageId = getInvitationLanguage(state)
  let language = state.languages.list.find(language => language.id === languageId)

  return {
    candidates: state.videoInterviewInvitation.candidates,
    questions: state.videoInterviewInvitation.questions,
    introductionVideo: state.videoInterviewInvitation.introductionVideo,
    language: language,
    expiryDays: state.videoInterviewInvitation.expiryDays,
    reminderDays: state.videoInterviewInvitation.reminderDays,
    emailTemplates: state.videoInterviewInvitation.emailTemplates,
    cancel: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview')
    },
  }
}

export default withRouter(connect(mapStateToProps)(VideoInterviewSummaryStep))
