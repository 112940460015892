import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import c from 'classnames'
import { injectIntl } from 'react-intl'
import FormElement from './FormElement'
import ReactTags from 'react-tag-autocomplete'
import * as moment from 'moment'
import momentDuration from 'moment-duration-format' // eslint-disable-line no-unused-vars
import { formId } from '../helpers'
import { toNumber } from '../helpers/normalize'
import config from '../config'

const durationOptions = (
  min = config.duration.min,
  max = config.duration.max,
  increment = config.duration.increment
) => {
  let options = []

  options.push([...Array(max / min)].map((value, i) => {
    let duration = (i * increment) + min

    return (
      <option key={i + 1} value={duration}>
        {moment.duration(duration, 'seconds').format({
          template: 'mm:ss',
          trim: false,
        })}
      </option>
    )
  }))

  return options
}

const languageOptions = languages => {
  let options = []

  options.push(languages.map(language => (
    <option key={language.id.toString()} value={language.id}>{language.name}</option>
  )))

  return options
}

const renderTextArea = ({ input, label, placeholder, rows = 4, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <textarea
        {...input}
        rows={rows}
        placeholder={placeholder}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const renderSelect = ({ input, label, items, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <select
        {...input}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      >
        {items}
      </select>
    </div>
  </div>
)

class QuestionForm extends Component {

  constructor(props) {
    super()

    this.state = {
      tags: (props.initialValues && props.initialValues.tags) || [],
    }

    this.addTag = this.addTag.bind(this)
    this.removeTag = this.removeTag.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  addTag(tag) {
    this.setState({
      tags: this.state.tags.concat(tag)
    })
  }

  removeTag(i) {
    let tags = this.state.tags
    tags.splice(i, 1)

    this.setState({
      tags: tags,
    })
  }

  handleSubmit(values) {
    return this.props.onSubmit(values, this.state.tags)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      languages,
      onCancel,
      tagSuggestions = [],
      allowTags = true,
      intl,
    } = this.props

    return (
      <FormElement onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="form-row">
          <Field
            label={intl.formatMessage({id: 'questions.addQuestion.textField.label'})}
            name="text"
            placeholder={intl.formatMessage({id: 'questions.addQuestion.textField.placeholder'})}
            component={renderTextArea}
          />
        </div>

        <div className="form-row">
          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'questions.addQuestion.languageField.label'})}
              name="languageId"
              component={renderSelect}
              items={languageOptions(languages)}
              normalize={toNumber}
            />
          </div>

          <div className="form-column">
            <Field
              label={intl.formatMessage({id: 'questions.addQuestion.durationField.label'})}
              name="duration"
              component={renderSelect}
              items={durationOptions()}
              normalize={toNumber}
            />
          </div>
        </div>

        {allowTags &&
          <div className="form-row">
            <div className="form-label">
              <label>{intl.formatMessage({id: 'questions.addQuestion.tagsField.label'})}</label>
            </div>

            <div className="form-control">
              <ReactTags
                tags={this.state.tags}
                placeholder={intl.formatMessage({id: 'questions.addQuestion.tagsField.placeholder'})}
                suggestions={tagSuggestions}
                onDelete={this.removeTag}
                onAddition={this.addTag}
                allowNew={false}
                autoresize={false}
                autofocus={false}
                minQueryLength={1}
              />
            </div>
          </div>
        }

        <div className="form-submit-row">
          <input
            type="submit"
            value={intl.formatMessage({id: 'questions.addQuestion.submitButton.label'})}
            className="button"
            disabled={submitting}
          />

          <span className="cancel">
            {intl.formatMessage({id: 'questions.addQuestion.cancelButton.conjunction'})}
            {' '}

            <Link
              to={typeof onCancel === 'string' ? onCancel : '#'}
              onClick={typeof onCancel === 'function' ? onCancel : null}
            >
              {intl.formatMessage({id: 'questions.addQuestion.cancelButton.label'})}
            </Link>
          </span>
        </div>
      </FormElement>
    )
  }
}

QuestionForm.propTypes = {
  languages: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  allowTags: PropTypes.bool,
  tagSuggestions: PropTypes.array,
}

const validate = values => {
  const errors = {}

  let fields = ['text', 'duration', 'languageId']
  fields.forEach(name => {
    if (!values[name]) {
      errors[name] = 'Required'
    }
  })

  return errors
}

export default injectIntl(reduxForm({
  form: 'question',
  validate: validate,
})(QuestionForm))
