import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'

import { createSet, updateSet } from '../actions/sets'
import { loadQuestions } from '../actions/questions'
import { getSet } from '../actions/api'
import { loadTags } from '../actions/tags'
import { filterQuestions, reduceQuestions, filterVideoInterviewLanguages, getLanguageByCode } from '../selectors'
import Form from '../components/QuestionSetForm'
import QuestionFilter from '../components/QuestionFilter'
import AvailableQuestions from '../components/AvailableQuestions'
import Spinner from '../components/Spinner'
import arrayMove from 'array-move'

class QuestionSetsForm extends Component {

  constructor(props) {
    super()

    this.state = {
      questionSet: null,
      selectedQuestions: [],
      filter: {
        search: '',
        languageId: null,
        tags: [],
      },
    }

    this.filter = this.filter.bind(this)
    this.selectQuestion = this.selectQuestion.bind(this)
    this.unSelectQuestion = this.unSelectQuestion.bind(this)
    this.sortQuestion = this.sortQuestion.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    props.dispatch(loadQuestions())
    props.dispatch(loadTags())

    if (props.match.params.id) {
      let id = parseInt(props.match.params.id, 10)

      getSet(props.clientId, id).then((response) => {
        let data = response.data
        this.setState({
          questionSet: {
            id: data.id,
            name: data.name,
            languageId: data.languageId,
          },
          selectedQuestions: data.questions,
        })
      })
    }
  }

  filter(filters) {
    this.setState({
      filter: {
        ...this.state.filter,
        ...filters,
      },
    })
  }

  selectQuestion(question) {
    let questions = this.state.selectedQuestions.concat()
    questions.push(question)

    this.setState({
      selectedQuestions: questions,
    })
  }

  unSelectQuestion(question) {
    let questions = this.state.selectedQuestions
    let index = questions.findIndex(q => q.id === question.id)

    if (index > -1) {
      questions.splice(index, 1);
    }

    this.setState({
      selectedQuestions: questions,
    })
  }

  sortQuestion({ oldIndex, newIndex }) {
    this.setState({
      selectedQuestions: arrayMove(this.state.selectedQuestions, oldIndex, newIndex),
    })
  }

  handleSubmit(values) {
    let result
    values = {
      ...values,
      questions: this.state.selectedQuestions,
    }

    if (this.state.questionSet) {
      result = this.props.dispatch(updateSet(this.state.questionSet, values))
    } else {
      result = this.props.dispatch(createSet(values))
    }

    return result.then(() => {
      this.props.history.push('/library/question-set')
    })
  }

  render() {
    const { intl, currentLanguage } = this.props

    if (!this.props.loaded || (this.props.match.params.id && !this.state.questionSet)) {
      return (
        <Spinner />
      )
    }

    const questions = reduceQuestions(
      filterQuestions(this.props.availableQuestions, this.state.filter),
      this.state.selectedQuestions,
    )

    return (
      <div className="inline-form" id="question-set-create-form">
        <Form
          onSubmit={this.handleSubmit}
          onCancel="/library/question-set"
          languages={this.props.languages}
          allLanguages={this.props.allLanguages}
          questions={this.state.selectedQuestions}
          onQuestionUnSelect={this.unSelectQuestion}
          onQuestionSort={this.sortQuestion}
          initialValues={{
            ...this.state.questionSet,
            ...(!this.state.questionSet ? {languageId: currentLanguage.id} : {}),
          }}
        />

        <div className="form-line" />

        <div className="form-row">
          <h2 className="item-list-title">
            {intl.formatMessage({id: 'questionSets.addSet.availableQuestions.title'})}
          </h2>

          <QuestionFilter
            onFilter={this.filter}
            languages={this.props.languages}
            tags={this.props.tags}
            autoFocus={false}
          />

          <AvailableQuestions
            languages={this.props.allLanguages}
            questions={questions}
            onSelect={this.selectQuestion}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.languages.loaded && state.questions.loaded,
    languages: filterVideoInterviewLanguages(state.languages.list),
    allLanguages: state.languages.list,
    availableQuestions: state.questions.list,
    tags: state.tags.list,
    clientId: state.identity.clientId,
    currentLanguage: getLanguageByCode(state.languages.list, state.currentLanguage),
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(QuestionSetsForm)))
