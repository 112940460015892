import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { reset as resetForm } from 'redux-form'
import { loadInterviewDetail, createNote } from '../actions/videoInterviews'
import Interview from '../components/VideoInterviewDetail'
import NotFound from '../components/NotFoundListItem'
import Spinner from '../components/Spinner'

class VideoInterviewDetail extends Component {

  constructor(props) {
    super()

    props.dispatch(loadInterviewDetail(parseInt(props.match.params.id, 10)))
  }

  render() {
    const {
      loaded,
      interview,
      interviewDetails,
      identity,
      onNoteSubmit
    } = this.props

    if (!loaded) {
      return (
        <Spinner />
      )
    } else if (loaded && !interview) {
      return (
        <NotFound message="Interview not found." />
      )
    }

    return (
      <Interview
        loaded={loaded}
        interview={interview}
        details={interviewDetails}
        identity={identity}
        onNoteSubmit={onNoteSubmit}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let interviewId = parseInt(ownProps.match.params.id, 10)
  let interview = state.videoInterviews.list.reduce((prev, current) => (
    current.id === interviewId ? current : prev
  ), null)

  let details = state.videoInterviews.details[interviewId] || null

  return {
    loaded: state.videoInterviews.loaded,
    interview: interview,
    interviewDetails: details,
    identity: state.identity,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action),
    onNoteSubmit: (interview, values) => {
      return dispatch(createNote(interview, values))
        .then(() => {
          dispatch(resetForm('videoInterviewComment'))
        })
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewDetail))
