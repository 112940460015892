import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import c from 'classnames'
import { credentialLetters } from '../helpers'
import NotFound from './NotFoundListItem'

const VideoInterviewCandidatesStep = ({ jobPosition, candidates, nextStep, cancel, onCandidateDelete, intl }) => (
  <div>
    <div className="content step-form-content">
      <h2>{intl.formatMessage({id: 'videoInterviews.invite.candidates.title'})}</h2>
      <FormattedMessage
        id={'videoInterviews.invite.candidates.description'}
        tagName="p"
        values={{
          candidates: `${candidates.length} ${intl.messages['candidates.candidate'][intl.formatPlural(candidates.length)]}`,
        }}
      />

      <div className="item-list">
        {candidates.map((candidate, i) => (
          <div
            key={candidate.id.toString()}
            className={c('item', { 'item-even': (i + 1) % 2 === 0, 'item-odd': (i + 1) % 2 !== 0})}
          >
            <div className="column">
              <span>
                {credentialLetters(candidate.firstName, candidate.lastName)}
              </span>
            </div>

            <div className="column candidate-info">
              {candidate.firstName + ' ' + candidate.lastName}
              <span>{candidate.email}</span>
            </div>

            <div className="column job-position">
              {jobPosition}
            </div>

            <div className="column column-actions">
              <ul>
                <li>
                  <a
                    href="#"
                    onClick={event => {
                      event.preventDefault()
                      onCandidateDelete(candidate)
                    }}
                  >
                    {intl.formatMessage({id: 'videoInterviews.invite.candidatesRemoveAction.label'})}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ))}

        {candidates.length === 0 &&
          <NotFound message={intl.formatMessage({id: 'videoInterviews.invite.candidates.blankslate'})} />
        }
      </div>
    </div>

    <div className="footer step-form-footer">
      <a href="#" className="button" onClick={nextStep}>
        {intl.formatMessage({id: 'videoInterviews.invite.nextStepButton.label'})}
      </a>

      <span className="cancel">
        {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.conjunction'})}
        {' '}

        <a href="#" onClick={cancel}>
          {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.label'})}
        </a>
      </span>
    </div>
  </div>
)

VideoInterviewCandidatesStep.propTypes = {
  jobPosition: PropTypes.string.isRequired,
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ).isRequired,
  nextStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  onCandidateDelete: PropTypes.func.isRequired,
}

export default injectIntl(VideoInterviewCandidatesStep)
