import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import c from 'classnames'
import { sumTotalDuration } from '../selectors'
import Duration from './Duration'

const VideoInterviewSummaryStep = (
  {
    candidates = [],
    questions = [],
    introductionVideo,
    language,
    expiryDays,
    reminderDays,
    emailTemplates = [],
    submit,
    cancel,
    submitting,
    intl,
  }) => {
  const emailTypes = emailTemplates.map(template => {
    return intl.formatMessage({id: `emailTemplates.type.${template.typeName}`})
  }).join(', ')

  return (
    <div>
      <div className="content step-form-content">
        <h2>{intl.formatMessage({id: 'videoInterviews.invite.summary.title'})}</h2>
        <p>{intl.formatMessage({id: 'videoInterviews.invite.summary.description'})}</p>

        <h3>{intl.formatMessage({id: 'videoInterviews.invite.summary.candidates.title'})}</h3>

        {candidates.length !== 0 &&
        <FormattedMessage
          id="videoInterviews.invite.summary.candidates.description"
          tagName="p"
          values={{
            candidates: `${candidates.length} ${intl.messages['candidates.candidate'][intl.formatPlural(candidates.length)]}`,
            candidatesLink: (
              <Link to="/video-interview/invite">
                {intl.formatMessage({id: 'videoInterviews.invite.summary.candidates.link.label'})}
              </Link>
            ),
          }}
        />
        }

        {candidates.length === 0 &&
        <div className="alert alert-error">
          <FormattedMessage
            id="videoInterviews.invite.summary.candidates.blankslate"
            values={{
              candidatesLink: (
                <Link to="/video-interview/invite">
                  {intl.formatMessage({id: 'videoInterviews.invite.summary.candidatesLink.label'})}
                </Link>
              ),
            }}
          />
        </div>
        }

        <div className="form-line" />

        <h3>{intl.formatMessage({id: 'videoInterviews.invite.summary.questions.title'})}</h3>
        {questions.length !== 0 &&
        <div>
          <FormattedMessage
            id="videoInterviews.invite.summary.questions.description"
            tagName="p"
            values={{
              questions: `${questions.length} ${intl.messages['questions.question'][intl.formatPlural(questions.length)]}`,
              totalDuration: (
                <Duration seconds={sumTotalDuration(questions)} />
              ),
            }}
          />

          <div className="summary-box">
            <ol>
              {questions.map(question => (
                <li key={question.id.toString()}>
                  {question.text}
                  {' '}
                  (<Duration seconds={question.duration} />)
                </li>
              ))}
            </ol>
          </div>

          <p className="note">
            <FormattedMessage
              id="videoInterviews.invite.summary.questions.note"
              values={{
                questionsLink: (
                  <Link to="/video-interview/invite/questions">
                    {intl.formatMessage({id: 'videoInterviews.invite.summary.questionsLink.label'})}
                  </Link>
                ),
              }}
            />
          </p>
        </div>
        }

        {questions.length === 0 &&
        <div className="alert alert-error">
          <FormattedMessage
            id="videoInterviews.invite.summary.questions.blankslate"
            values={{
              questionsLink: (
                <Link to="/video-interview/invite/questions">
                  {intl.formatMessage({id: 'videoInterviews.invite.summary.questionsLink.label'})}
                </Link>
              ),
            }}
          />
        </div>
        }

        <div className="form-line" />

        <h3>{intl.formatMessage({id: 'videoInterviews.invite.summary.introductionVideo.title'})}</h3>

        {introductionVideo &&
        <FormattedMessage
          id="videoInterviews.invite.summary.introductionVideo.description"
          tagName="p"
          values={{
            videoTitle: (
              <strong>{introductionVideo.name}</strong>
            ),
          }}
        />
        }

        {!introductionVideo &&
        <FormattedMessage
          id="videoInterviews.invite.summary.introductionVideo.descriptionNoVideo"
          tagName="p"
        />
        }

        <p className="note">
          <FormattedMessage
            id="videoInterviews.invite.summary.introductionVideo.note"
            values={{
              introductionVideoLink: (
                <Link to="/video-interview/invite/introduction-video">
                  {intl.formatMessage({id: 'videoInterviews.invite.summary.introductionVideoLink.label'})}
                </Link>
              ),
            }}
          />
        </p>

        <div className="form-line" />

        <h3>{intl.formatMessage({id: 'videoInterviews.invite.summary.language.title'})}</h3>
        <FormattedMessage
          id="videoInterviews.invite.summary.language.description"
          tagName="p"
          values={{
            language: language.name,
          }}
        />
        <p className="note">
          <FormattedMessage
            id="videoInterviews.invite.summary.language.note"
            values={{
              detailsLink: (
                <Link to="/video-interview/invite/details">
                  {intl.formatMessage({id: 'videoInterviews.invite.summary.detailsLink.label'})}
                </Link>
              ),
            }}
          />
        </p>

        <div className="form-line" />

        <h3>{intl.formatMessage({id: 'videoInterviews.invite.summary.dates.title'})}</h3>
        <FormattedMessage
          id="videoInterviews.invite.summary.dates.description"
          tagName="p"
          values={{
            expiryInterval: (
              <strong>
                <FormattedMessage
                  id="videoInterviews.invite.expiryInterval"
                  values={{
                    days: `${expiryDays} ${intl.messages['videoInterviews.invite.days'][intl.formatPlural(expiryDays)]}`,
                  }}
                />
              </strong>
            ),
            reminderInterval: (
              <strong>
                <FormattedMessage
                  id="videoInterviews.invite.reminderInterval"
                  values={{
                    days: `${reminderDays} ${intl.messages['videoInterviews.invite.days'][intl.formatPlural(reminderDays)]}`,
                  }}
                />
              </strong>
            ),
          }}
        />
        <p className="note">
          <FormattedMessage
            id="videoInterviews.invite.summary.dates.note"
            values={{
              detailsLink: (
                <Link to="/video-interview/invite/details">
                  {intl.formatMessage({id: 'videoInterviews.invite.summary.detailsLink.label'})}
                </Link>
              ),
            }}
          />
        </p>

        <div className="form-line" />

        <h3>{intl.formatMessage({id: 'videoInterviews.invite.summary.emails.title'})}</h3>
        <FormattedMessage
          id="videoInterviews.invite.summary.emails.description"
          tagName="p"
          values={{
            emailTemplatesNumber: emailTemplates.length,
            emailTemplatesList: emailTypes,
          }}
        />
        <p className="note">
          <FormattedMessage
            id="videoInterviews.invite.summary.emails.note"
            values={{
              detailsLink: (
                <Link to="/video-interview/invite/details">
                  {intl.formatMessage({id: 'videoInterviews.invite.summary.detailsLink.label'})}
                </Link>
              ),
            }}
          />
        </p>
      </div>

      <div className="footer step-form-footer">
        <a
          href="#"
          className={c('button', {'disabled': submitting})}
          onClick={!submitting ? submit : null}
        >
          <FormattedMessage
            id={'videoInterviews.invite.submitButton.label'}
            values={{
              candidates: `${candidates.length} ${intl.messages['candidates.candidate'][intl.formatPlural(candidates.length)]}`
            }}
          />
        </a>

        <span className="cancel">
        {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.conjunction'})}
          {' '}

          <a href="#" onClick={cancel}>
          {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.label'})}
        </a>
      </span>
      </div>
    </div>
  )
}

VideoInterviewSummaryStep.propTypes = {
  candidates: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  introductionVideo: PropTypes.object,
  language: PropTypes.object.isRequired,
  expiryDays: PropTypes.number.isRequired,
  reminderDays: PropTypes.number.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
}

VideoInterviewSummaryStep.defaultProps = {
  submitting: false,
}

export default injectIntl(VideoInterviewSummaryStep)
