import * as actionType from '../constants/actionTypes'

const initialState = null

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.IDENTITY_DATA_LOAD:
      return {
        ...action.identity,
      }

    case actionType.PROFILE_UPDATE:
      let { logo, ...sanitizedProfile } = action.profile

      return {
        ...state,
        client: {
          ...state.client,
          ...sanitizedProfile
        }
      }

    case actionType.PROFILE_LOGO_UPDATE:
      return {
        ...state,
        client: {
          ...state.client,
          logo: action.logo,
        },
      }

    case actionType.PROFILE_LOGO_REMOVE:
      return {
        ...state,
        client: {
          ...state.client,
          logo: null,
        },
      }

    default:
      return state
  }
}
