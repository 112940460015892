import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { updateTag, removeTag } from '../actions/tags'
import { removeQuestionTag } from '../actions/questions'
import { getTagQuestions } from '../selectors'
import Form from '../components/TagForm'

class TagForm extends Component {

  constructor() {
    super()

    this.handleRemoveTag = this.handleRemoveTag.bind(this)
    this.handleRemoveQuestion = this.handleRemoveQuestion.bind(this)
  }

  handleRemoveTag(event, tag) {
    event.preventDefault()
    this.props.dispatch(removeTag(tag))
  }

  handleRemoveQuestion(event, question) {
    event.preventDefault()
    this.props.dispatch(removeQuestionTag(question, this.props.tag))
  }

  render() {
    const { onSubmit, tag, tagQuestions, intl } = this.props

    return (
      <div className="inline-form">
        <h2 className="inline-form-title">
          {intl.formatMessage({id: 'questionTags.editTag.title'})}
          {' '}
          {tag.name}
          {' '}
          (<span className="tag-attr-noq">{tag.questions.length}</span>)
        </h2>

        <p className="inline-form-remove">
          <a
            href="#"
            onClick={(event) => this.handleRemoveTag(event, tag)}
          >
            <i className="fa fa-times" />
            {intl.formatMessage({id: 'questionTags.editTag.removeButton.label'})}
          </a>
        </p>

        <Form
          form={`tag-${tag.id}`}
          questions={tagQuestions}
          onSubmit={values => onSubmit(tag, values)}
          onCancel="/library/tag"
          onQuestionDelete={this.handleRemoveQuestion}
          initialValues={{
            ...tag,
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let tag = state.tags.list.find(tag => tag.id === parseInt(ownProps.match.params.id, 10))

  return {
    tag: tag,
    tagQuestions: getTagQuestions(state.questions.list, tag)
  }
}

const mapDispatchToProps = (dispatch, ownProps)=> {
  return {
    dispatch: action => dispatch(action),
    onSubmit: (tag, values) => {
      return dispatch(updateTag(tag, values))
        .then(() => {
          ownProps.history.push('/library/tag')
        })
    }
  }
}

export default injectIntl(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TagForm)))
