import React, { Component } from 'react'
import { connect } from 'react-redux'
import StepForm from '../components/StepForm'
import { loadEmailTemplates } from '../actions/emailTemplates'

class VideoInterviewInvitation extends Component {

  constructor(props) {
    super()

    this.steps = [
      {
        title: 'videoInterviews.invite.candidates.title',
        link: '/video-interview/invite',
        indexLink: true,
      },
      {
        title: 'videoInterviews.invite.questions.title',
        link: '/video-interview/invite/questions',
      },
      {
        title: 'videoInterviews.invite.introductionVideo.title',
        link: '/video-interview/invite/introduction-video',
      },
      {
        title: 'videoInterviews.invite.details.title',
        link: '/video-interview/invite/details',
      },
      {
        title: 'videoInterviews.invite.summary.title',
        link: '/video-interview/invite/summary',
      },
    ]

    props.dispatch(loadEmailTemplates())
  }

  render() {
    return (
      <StepForm steps={this.steps}>
        {this.props.children}
      </StepForm>
    )
  }
}

export default connect()(VideoInterviewInvitation)
