import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import c from 'classnames'
import validator from 'validator'
import FormElement from './FormElement'
import { formId } from '../helpers'

const renderField = ({ input, label, type = 'text', meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <input
        {...input}
        type={type}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const renderTextArea = ({ input, label, placeholder, rows = 4, meta, id = formId() }) => (
  <div>
    <div className="form-label">
      <label htmlFor={id}>{label}</label>
    </div>

    <div className="form-control">
      <textarea
        {...input}
        rows={rows}
        placeholder={placeholder}
        className={c({'input': true, 'invalidControl': meta.touched && meta.error})}
        id={id}
      />
    </div>
  </div>
)

const EmailTemplateInlineForm = (
  {
    handleSubmit,
    submitting,
    onSubmit,
    onCancel,
    intl,
  }) => (
  <FormElement onSubmit={handleSubmit(onSubmit)}>
    <div className="form-row">
      <Field
        label={intl.formatMessage({id: 'videoInterviews.invite.details.emails.subjectField.label'})}
        name="subject"
        component={renderField}
      />
    </div>

    <div className="form-row">
      <Field
        label={intl.formatMessage({id: 'videoInterviews.invite.details.emails.contentField.label'})}
        name="content"
        component={renderTextArea}
      />
    </div>

    <input
      type="submit"
      value={intl.formatMessage({id: 'videoInterviews.invite.details.emails.submitButton.label'})}
      className="button"
      disabled={submitting}
    />

    <span className="cancel">
      {intl.formatMessage({id: 'videoInterviews.invite.details.emails.cancelButton.conjunction'})}
      {' '}

      <Link
        to={typeof onCancel === 'string' ? onCancel : '#'}
        onClick={typeof onCancel === 'function' ? onCancel : null}
      >
        {intl.formatMessage({id: 'videoInterviews.invite.details.emails.cancelButton.label'})}
      </Link>
    </span>
  </FormElement>
)

EmailTemplateInlineForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
}

const validate = values => {
  const errors = {}

  // validate identity number
  if (!values.subject) {
    errors.subject = 'Required'
  } else if (!validator.isLength(values.subject, { max: 255 })) {
    errors.subject = 'Too long'
  }

  // validate vat id
  if (!values.content) {
    errors.content = 'Required'
  }

  return errors
}

export default injectIntl(reduxForm({
  form: 'videoInterviewEmailTemplate',
  validate: validate,
})(EmailTemplateInlineForm))
