import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import c from 'classnames'
import { injectIntl } from 'react-intl'

const EmailTemplates = ({ languages, templates, editingId, form, intl }) => {
  const items = templates.map((template, i) => {
    let itemClassName = c('item', {'item-odd': (i + 1) % 2 !== 0, 'item-even': (i + 1) % 2 === 0})
    return (
      <div key={template.id.toString()} className={editingId !== template.id ? itemClassName : null}>
        <div className={editingId === template.id ? itemClassName : null}>
          <div className="column column-iterator">
            {i + 1}
          </div>

          <div className="column">
            <span className="email-template-attr-subject alt">
              {template.subject}
            </span>,

            {' '}

            <span className="email-template-attr-language">
              {languages.find(x => x.id === template.languageId)?.name}
            </span>,

            {' '}

            <span className="email-template-attr-type">
              {intl.formatMessage({id: `emailTemplates.type.${template.typeName}`})}
            </span>
          </div>

          <div className="column column-actions">
            <ul>
              <li>
                <Link to={`/settings/email-template/${template.id}`}>
                  {intl.formatMessage({id: 'emailTemplates.list.editAction.label'})}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {editingId === template.id ? form : null}
      </div>
    )
  })

  return (
    <div className="item-list">
      {items}
    </div>
  )
}

EmailTemplates.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      subject: PropTypes.string.isRequired,
      languageId: PropTypes.number.isRequired,
      typeName: PropTypes.string.isRequired,
      editing: PropTypes.bool,
    })
  ).isRequired,
  editingId: PropTypes.number,
  form: PropTypes.element,
}

export default injectIntl(EmailTemplates)
