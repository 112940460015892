import React from 'react'
import { FormattedMessage } from 'react-intl'
import NavLink from './NavLink'

const LibraryNavigation = () => (
  <div className="hub-secondary-navigation">
    <ul>
      {/*<NavLink to="/library/introduction-video"
               className="item">
        <i className="fa fa-tv" />
        Introduction Videos
      </NavLink>*/}

      <NavLink to="/library/question"
               className="item">
        <i className="fa fa-question-circle" />
        <FormattedMessage id={'questions.navigationLabel'} />
      </NavLink>

      <NavLink to="/library/tag"
               className="item">
        <i className="fa fa-tags" />
        <FormattedMessage id={'questionTags.navigationLabel'} />
      </NavLink>

      <NavLink to="/library/question-set"
               className="item">
        <i className="fa fa-book" />
        <FormattedMessage id={'questionSets.navigationLabel'} />
      </NavLink>
    </ul>
  </div>
)

export default LibraryNavigation
