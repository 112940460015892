import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import QuestionList from './SortableQuestionList'
import SetsList from './QuestionSetList'
import QuestionForm from './QuestionForm'
import Spinner from './Spinner'
import { getLanguageByCode } from '../selectors'

class VideoInterviewQuestionsStep extends Component {

  constructor() {
    super()

    this.state = {
      createFormActive: false,
    }

    this.toggleForm = this.toggleForm.bind(this)
    this.createQuestion = this.createQuestion.bind(this)

    this.renderSets = this.renderSets.bind(this)
    this.renderQuestions = this.renderQuestions.bind(this)
  }

  componentDidMount() {
    if (this.props.match.params.id && this.props.questions.length === 0) {
      this.props.history.push('/video-interview/invite/questions')
    }
  }

  toggleForm(event) {
    if (event) event.preventDefault()

    this.setState({
      createFormActive: !this.state.createFormActive,
    })
  }

  createQuestion(values) {
    this.props.onQuestionCreate(values)
    this.setState({
      createFormActive: false,
    })
  }

  render() {
    let {
      onShowSets,
      showSets,
      nextStep,
      cancel,
      intl,
    } = this.props

    return (
      <div>
        <div className="content step-form-content">
          <h2>{intl.formatMessage({id: 'videoInterviews.invite.questions.title'})}</h2>
          <p>{intl.formatMessage({id: 'videoInterviews.invite.questions.description'})}</p>

          {/*<div className="form-group">
           <label className="form-label">I want to use question suggestion for the specified Job Position:</label>
           <select>
           <option value="">Marketing Executive</option>
           </select>
           <a href="" className="button small">Get Question Suggestion</a>
           </div>*/}

          <div className="form-group">
            <p>
              {intl.formatMessage({id: 'videoInterviews.invite.questions.selectSetNote'})}
              {' '}
              <a href="#" className="button small" onClick={onShowSets}>
                {intl.formatMessage({id: 'videoInterviews.invite.questions.selectSetButton.label'})}
              </a>
            </p>
          </div>

          <div className="form-line"/>

          {showSets ? this.renderSets() : this.renderQuestions()}
        </div>

        <div className="footer step-form-footer">
          <a href="#" className="button" onClick={nextStep}>
            {intl.formatMessage({id: 'videoInterviews.invite.nextStepButton.label'})}
          </a>

          <span className="cancel">
            {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.conjunction'})}
            {' '}

            <a href="#" onClick={cancel}>
              {intl.formatMessage({id: 'videoInterviews.invite.cancelButton.label'})}
            </a>
          </span>
        </div>
      </div>
    )
  }

  renderQuestions() {
    let { questions, onQuestionDelete, onQuestionSort, languages, match, questionForm, intl, currentLanguage } = this.props

    let actions = [
      {
        label: intl.formatMessage({id: 'videoInterviews.invite.questions.list.editAction.label'}),
        iconClassName: 'fa-pencil',
        link: (question) => {
          return `/video-interview/invite/questions/${question.id}`
        },
      },
      {
        label: intl.formatMessage({id: 'videoInterviews.invite.questions.list.removeAction.label'}),
        iconClassName: 'fa-times',
        handle: onQuestionDelete,
      }
    ]

    return (
      <div>
        <h3>
          {intl.formatMessage({id: 'videoInterviews.invite.questions.list.title'})}
        </h3>
        <p>
          <a href="#" onClick={this.toggleForm} className="button">
            {intl.formatMessage({id: 'videoInterviews.invite.questions.addQuestionButton.label'})}
          </a>
        </p>

        {this.state.createFormActive &&
          <div className="inline-form" id="question-create-form">
            <QuestionForm
              form="questionCreate"
              languages={languages}
              onSubmit={this.createQuestion}
              onCancel={this.toggleForm}
              allowTags={false}
              initialValues={{
                languageId: currentLanguage.id,
                duration: 30,
              }}
            />
          </div>
        }

        <QuestionList
          languages={languages}
          questions={questions}
          actions={actions}
          editingId={match.params.id ? parseInt(match.params.id, 10) : null}
          form={questionForm}
          onSort={onQuestionSort}
        />
      </div>
    )
  }

  renderSets() {
    let { sets, setsLoaded, onShowSets, onSelectSet, intl, languages } = this.props

    let actions = [
      {
        label: intl.formatMessage({id: 'videoInterviews.invite.questions.selectSetSection.selectAction.label'}),
        handle: onSelectSet,
      },
    ]

    return (
      <div>
        <h3>
          {intl.formatMessage({id: 'videoInterviews.invite.questions.selectSetSection.title'})}
        </h3>
        <p>
          <a href="#" onClick={onShowSets}>
            {intl.formatMessage({id: 'videoInterviews.invite.questions.selectSetSection.backButton.label'})}
          </a>
        </p>

        {!setsLoaded &&
          <Spinner />
        }

        {setsLoaded &&
          <SetsList
            languages={languages}
            sets={sets}
            actions={actions}
          />
        }
      </div>
    )
  }
}

VideoInterviewQuestionsStep.propTypes = {
  questions: PropTypes.array.isRequired,
  sets: PropTypes.array.isRequired,
  setsLoaded: PropTypes.bool.isRequired,
  onShowSets: PropTypes.func.isRequired,
  onSelectSet: PropTypes.func.isRequired,
  onQuestionEdit: PropTypes.func.isRequired,
  onQuestionDelete: PropTypes.func.isRequired,
  onQuestionCreate: PropTypes.func.isRequired,
  onQuestionSort: PropTypes.func.isRequired,
  showSets: PropTypes.bool.isRequired,
  languages: PropTypes.array.isRequired,
  nextStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    languages: state.languages.list,
    currentLanguage: getLanguageByCode(state.languages.list, state.currentLanguage),
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(VideoInterviewQuestionsStep)))
