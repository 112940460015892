import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import FormElement from '../components/FormElement'
import * as forms from '../constants/forms'
import { formId } from '../helpers'

class TagFilter extends Component {

  constructor() {
    super()

    this.state = {
      search: '',
      sortBy: forms.FILTER_ALPHABET,
    }

    this.focus = this.focus.bind(this)
    this.reset = this.reset.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this)

    this.searchFieldId = formId()
    this.sortFieldId = formId()
  }

  componentDidMount() {
    this.focus()
  }

  focus() {
    this.searchInput.focus()
  }

  reset() {
    let state = {
      search: '',
    }

    this.setState(state)
    this.props.onFilter(state)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    let state = {
      [name]: value,
    }

    this.setState(state)
    this.props.onFilter(state)
  }

  handleInputKeyDown(event) {
    if (event.keyCode === 27) { // esc pressed
      event.preventDefault()
      this.reset()
    }
  }

  handleSubmit(event) {
    event.preventDefault()
  }

  render() {
    const { intl } = this.props

    return (
      <div className="filter" id="tag-list-filter">
        <FormElement onSubmit={this.handleSubmit}>
          <div className="filter-column filter-column-left">
            <label htmlFor={this.searchFieldId} className="label">
              {intl.formatMessage({id: 'questionTags.filter.searchField.label'})}
            </label>

            <input type="text"
                   name="search"
                   value={this.state.search}
                   autoComplete="off"
                   className="input"
                   id={this.searchFieldId}
                   ref={input => this.searchInput = input}
                   onKeyDown={this.handleInputKeyDown}
                   onChange={this.handleInputChange} />
          </div>

          <div className="filter-column filter-column-right">
            <label htmlFor={this.sortFieldId} className="label">
              {intl.formatMessage({id: 'questionTags.filter.sortField.label'})}
            </label>

            <select name="sortBy"
                    value={this.state.sortBy}
                    className="input"
                    id={this.sortFieldId}
                    ref={input => this.sortInput = input}
                    onChange={this.handleInputChange}>
              <option value={forms.FILTER_ALPHABET}>
                {intl.formatMessage({id: 'questionTags.filter.sortField.alphabetOption'})}
              </option>
              <option value={forms.FILTER_OCCURENCE}>
                {intl.formatMessage({id: 'questionTags.filter.sortField.occurenceOption'})}
              </option>
            </select>
          </div>
        </FormElement>
      </div>
    )
  }
}

TagFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
}

export default injectIntl(TagFilter)
