export const IDENTITY_DATA_LOAD = 'IDENTITY_DATA_LOAD'
export const INSTANCE_DATA_LOAD = 'INSTANCE_DATA_LOAD'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_LOGO_UPDATE = 'PROFILE_LOGO_UPDATE'
export const PROFILE_LOGO_REMOVE = 'PROFILE_LOGO_REMOVE'

export const QUESTIONS_DATA_LOAD = 'QUESTIONS_DATA_LOAD'
export const QUESTIONS_FILTER = 'QUESTION_FILTER'
export const QUESTIONS_DATA_REFRESH = 'QUESTIONS_DATA_REFRESH'
export const QUESTIONS_REMOVE = 'QUESTIONS_REMOVE'

export const QUESTION_SETS_DATA_LOAD = 'QUESTION_SETS_DATA_LOAD'
export const QUESTION_SETS_FILTER = 'QUESTION_SETS_FILTER'
export const QUESTION_SETS_REMOVE = 'QUESTION_SETS_REMOVE'

export const LANGUAGES_DATA_LOAD = 'LANGUAGES_DATA_LOAD'
export const LANGUAGES_ACTIVATE = 'LANGUAGES_ACTIVATE'
export const LANGUAGES_DEACTIVATE = 'LANGUAGES_DEACTIVATE'
export const LANGUAGES_SET_DEFAULT = 'LANGUAGES_SET_DEFAULT'

export const COUNTRIES_DATA_LOAD = 'COUNTRIES_DATA_LOAD'

export const TAGS_DATA_LOAD = 'TAGS_DATA_LOAD'
export const TAGS_FILTER = 'TAGS_FILTER'
export const TAGS_DATA_REFRESH = 'TAGS_DATA_REFRESH'
export const TAGS_REMOVE = 'TAGS_REMOVE'

export const EMAIL_TEMPLATES_DATA_LOAD = 'EMAIL_TEMPLATES_DATA_LOAD'

export const CONFIRM_DIALOG_SHOW = 'CONFIRM_DIALOG_SHOW'
export const CONFIRM_DIALOG_CONFIRM = 'CONFIRM_DIALOG_CONFIRM'
export const CONFIRM_DIALOG_CANCEL = 'CONFIRM_DIALOG_CANCEL'

export const CURRENT_LANGUAGE_CHANGE = 'CURRENT_LANGUAGE_CHANGE'

export const VIDEO_INTERVIEWS_DATA_LOAD = 'VIDEO_INTERVIEWS_DATA_LOAD'
export const VIDEO_INTERVIEWS_DETAILS_LOAD = 'VIDEO_INTERVIEWS_DETAILS_LOAD'
export const VIDEO_INTERVIEWS_SELECT_SET = 'VIDEO_INTERVIEWS_SELECT_SET'
export const VIDEO_INTERVIEWS_CREATE_QUESTION = 'VIDEO_INTERVIEWS_CREATE_QUESTION'
export const VIDEO_INTERVIEWS_REMOVE_QUESTION = 'VIDEO_INTERVIEWS_REMOVE_QUESTION'
export const VIDEO_INTERVIEWS_UPDATE_QUESTION = 'VIDEO_INTERVIEWS_UPDATE_QUESTION'
export const VIDEO_INTERVIEWS_DEFINITION_LANGUAGE = 'VIDEO_INTERVIEWS_DEFINITION_LANGUAGE'
export const VIDEO_INTERVIEWS_DEFINITION_EXPIRY = 'VIDEO_INTERVIEWS_DEFINITION_EXPIRY'
export const VIDEO_INTERVIEWS_DEFINITION_REMINDER = 'VIDEO_INTERVIEWS_DEFINITION_REMINDER'
export const VIDEO_INTERVIEWS_EMAIL_TEMPLATE_UPDATE = 'VIDEO_INTERVIEWS_EMAIL_TEMPLATE_UPDATE'
export const VIDEO_INTERVIEWS_REMOVE_CANDIDATE = 'VIDEO_INTERVIEWS_REMOVE_CANDIDATE'
export const VIDEO_INTERVIEWS_SORT_QUESTIONS = 'VIDEO_INTERVIEWS_SORT_QUESTIONS'
