import React from 'react'
import PropTypes from 'prop-types'
import { credentialLetters } from '../helpers'
import { DateTime } from './Date'

const Note = ({ author: { firstName, lastName }, content, created }) => (
  <div className="item">
    <div className="header">
      <span>{credentialLetters(firstName, lastName)}</span>
    </div>

    <div className="content">
      <p>{content}</p>
      <p className="date">
        <DateTime from={created} />
      </p>
    </div>
  </div>
)

Note.propTypes = {
  content: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  author: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
}

export default Note
