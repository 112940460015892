import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { updateQuestion } from '../actions/videoInterviews'
import Form from '../components/QuestionForm'

class VideoInterviewQuestionsStepQuestionForm extends Component {

  constructor(props) {
    super()

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    this.props.dispatch(updateQuestion(this.props.question, values))
      .then(() => {
        this.props.history.push('/video-interview/invite/questions')
      })
  }

  render() {
    let { question, languages } = this.props

    return (
      <Form
        form={`question-${question.id}`}
        languages={languages}
        onSubmit={this.handleSubmit}
        onCancel="/video-interview/invite/questions"
        allowTags={false}
        initialValues={question ? {
          ...question,
          languageId: question.languageId,
        } : {}}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let questions = state.videoInterviewInvitation.questions

  return {
    languages: state.languages.list,
    question: questions.find(question => question.id === parseInt(ownProps.match.params.id, 10)),
  }
}

export default withRouter(connect(mapStateToProps)(VideoInterviewQuestionsStepQuestionForm))
