import * as actionType from '../constants/actionTypes'

const initialState = {
  loaded: false,
  list: [],
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.VIDEO_INTERVIEWS_DATA_LOAD:
      let positions = []
      action.interviews.map(x => {
        if (positions.indexOf(x.jobPositinoLabel) === -1) {
          positions.push(x.jobPositinoLabel)
        }
      })

      return {
        ...state,
        loaded: true,
        list: positions,
      }

    default:
      return state
  }
}
