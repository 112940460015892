let config = {
  environment: process.env.NODE_ENV,
  duration: {
    min: 30,
    max: 900,
    increment: 30,
  },

  debug: true,
  ravenDsn: process.env.REACT_APP_RAVEN_DSN,
  api: {
    base: process.env.REACT_APP_BASE_API_URL,
    cors: false,
  }
}

export default config
