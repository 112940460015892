import * as actionTypes from '../constants/actionTypes'
import { getTags, postTag, putTag, removeTag as removeTagApi } from './api'
import { getTagQuestions } from '../selectors'

function loadTagsFromApi(onLoad) {
  return (dispatch, getState) => {
    return getTags(getState().identity.clientId).then((response) => {
      dispatch({
        type: actionTypes.TAGS_DATA_LOAD,
        tags: response.data,
      })

      if (onLoad) onLoad()
    })
  }
}

export function reloadTags() {
  return (dispatch, getState) => {
    return dispatch(loadTagsFromApi())
  }
}

export function loadTags() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().tags.loaded === true) {
      return
    }

    return dispatch(reloadTags())
  }
}

export function filterTags(filter) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TAGS_FILTER,
      filter: filter,
    })
  }
}

export function createTag(values, onCreate) {
  return (dispatch, getState) => {
    return postTag(getState().identity.clientId, values).then(() => {
      dispatch(reloadTags())
    })
  }
}

export function updateTag(tag, values) {
  return (dispatch, getState) => {
    const state = getState()
    const values2 = { ...values, clientId: state.identity.clientId }

    return putTag(state.identity.clientId, tag.id, values2).then(() => {
      dispatch(reloadTags())
    })
  }
}

export function removeTag(tag) {
  return (dispatch, getState, { intl }) => {
    const state = getState()
    const tagQuestions = getTagQuestions(state.questions.list, tag)
    const intlInstance = intl(state);

    let message = intlInstance.formatMessage({id: 'questionTags.removeDialog.message'})
    if (tagQuestions.length > 0) {
      let pluralForm = intlInstance.formatPlural(tagQuestions.length)
      let pluralizedQuestion = intlInstance.messages['questions.question'][pluralForm]

      message += '\n\n'
      message += intlInstance.formatMessage({id: 'questionTags.removeDialog.messageAddition'}, {
        questionsAmount: `${tagQuestions.length} ${pluralizedQuestion}`,
      })
    }

    dispatch({
      type: actionTypes.CONFIRM_DIALOG_SHOW,
      message: message,
      onCancel: () => {
        dispatch({
          type: actionTypes.CONFIRM_DIALOG_CANCEL,
        })
      },
      onConfirm: () => {
        removeTagApi(state.identity.clientId, tag.id).then(() => {
          dispatch({
            type: actionTypes.CONFIRM_DIALOG_CONFIRM,
          })

          dispatch({
            type: actionTypes.TAGS_REMOVE,
            id: tag.id,
          })
        })
      },
    })
  }
}
