import * as actionType from '../constants/actionTypes'

const initialState = null

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.CURRENT_LANGUAGE_CHANGE:
      return action.language

    default:
      return state
  }
}
