import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { updateEmailTemplate } from '../actions/emailTemplates'
import Form from '../components/EmailTemplateForm'

class EmailTemplateForm extends Component {

  render() {
    const { emailTemplate, onSubmit } = this.props

    return (
      <Form
        form={`emailTemplate-${emailTemplate.id}`}
        onSubmit={values => onSubmit(emailTemplate, values)}
        onCancel="/settings/email-template"
        initialValues={emailTemplate}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    emailTemplate: state.emailTemplates.list.find(template => template.id === parseInt(ownProps.match.params.id, 10)),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: action => dispatch(action),
    onSubmit: (emailTemplate, values) => {
      return dispatch(updateEmailTemplate(emailTemplate, values))
        .then(() => {
          ownProps.history.push('/settings/email-template')
        })
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateForm))
