import axios from 'axios'
import config from '../config'

const limit = '?Limit=10000'

let httpClient = axios.create({
  baseURL: config.api.base,
  withCredentials: config.api.cors,
})

export function getInstance(partnerDomain) {
  return httpClient.get(`/ShovelPartner/${partnerDomain}`)
}

export function setHash(hash) {
  httpClient.defaults.headers = {
    Authorization: hash,
  }
}

export function getIdentity(convertToMp4) {
  return httpClient.get(`/Shovel/Identity${convertToMp4 ? "?convertToMp4=true" : ""}`)
}

export function putProfile(clientId, values) {
  return httpClient.put(`/Client/${clientId}`, values)
}

export function postLogo(clientId, logo) {
  let data = new FormData()
  data.append('file', logo)

  return httpClient.post(`/client/${clientId}/upload/logo`, data, {
    'content-type': 'multipart/form-data',
  })
}

//todo: !
export function deleteLogo() {
  return httpClient.delete('/profile/logo')
}

export function getQuestions(clientId) {
  return httpClient.get(`/client/${clientId}/Question${limit}`)
}

export function postQuestion(clientId, values) {
  return httpClient.post(`/client/${clientId}/Question`, values)
}

export function putQuestion(clientId, id, values) {
  return httpClient.put(`/client/${clientId}/Question/${id}`, values)
}

export function removeQuestion(clientId, id) {
  return httpClient.delete(`/client/${clientId}/Question/${id}`)
}

export function deleteQuestionTag(clientId, questionId, tagId) {
  return httpClient.delete(`/client/${clientId}/Question/${questionId}/tag/${tagId}`)
}

export function getSets(clientId) {
  return httpClient.get(`/client/${clientId}/QuestionSet${limit}`)
}

export function getSet(clientId, id) {
  return httpClient.get(`/client/${clientId}/QuestionSet/${id}`)
}

export function postSet(clientId, values) {
  return httpClient.post(`/client/${clientId}/QuestionSet`, values)
}

export function putSet(clientId, id, values) {
  return httpClient.put(`/client/${clientId}/QuestionSet/${id}`, values)
}

export function removeSet(clientId, id) {
  return httpClient.delete(`/client/${clientId}/QuestionSet/${id}`)
}

export function getLanguages() {
  return httpClient.get(`/Language${limit}`)
}

export function getClientLanguages() {
  return httpClient.get('/ClientLanguage')
}

export function createClientLanguage(languageId) {
  return httpClient.post('/ClientLanguage', {
    id: languageId,
  })
}

export function removeClientLanguage(languageId) {
  return httpClient.delete(`/ClientLanguage/${languageId}`)
}

export function setLanguageDefault(languageId, type) {
  // validate type
  switch (type) {
    case 'IsAppDefault':
    case 'IsRecorderDefault':
      break

    default:
      throw new Error('Unable to set language as default: unknown language type.')
  }

  return httpClient.put(`/ClientLanguage/${languageId}/default/${type}`)
}

export function getCountries() {
  return httpClient.get(`/Country${limit}`)
}

export function getTags(clientId) {
  return httpClient.get(`/client/${clientId}/Tag${limit}`)
}

export function postTag(clientId, values) {
  return httpClient.post(`/client/${clientId}/Tag`, values)
}

export function putTag(clientId, id, values) {
  return httpClient.put(`/client/${clientId}/Tag/${id}`, values)
}

export function removeTag(clientId, id) {
  return httpClient.delete(`/client/${clientId}/Tag/${id}`)
}

export function getEmailTemplates() {
  return httpClient.get(`/EmailTemplate${limit}`)
}

export function putEmailTemplate(id, values) {
  return httpClient.put(`/EmailTemplate/${id}`, values)
}

export function getVideoInterviews(clientId) {
  return httpClient.get(`/client/${clientId}/VideoInterview${limit}`)
}

export function getVideoInterview(clientId, id, convertToMp4) {
  return httpClient.get(`/client/${clientId}/VideoInterview/${id}${convertToMp4 ? "?convertToMp4=true" : ""}`)
}

export function postVideoInterviewDefinition(clientId, values) {
  return httpClient.post(`/client/${clientId}/videointerview/definition`, values)
}

export function postVideoInterviewDefinitionQuestion(clientId, definitionId, values) {
  return httpClient.post(`/client/${clientId}/videointerview/definition/${definitionId}/question`, values)
}

export function postVideoInterview(clientId, values) {
  return httpClient.post(`/client/${clientId}/VideoInterview`, values)
}

export function postVideoInterviewNote(clientId, interviewId, noteValues) {
  return httpClient.post(`/client/${clientId}/VideoInterview/${interviewId}/note`, noteValues)
}

export function getTranslations(locale) {
  return axios.get(`/locales/${locale}.json `)
}
