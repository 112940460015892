import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { loadInterviews } from '../actions/videoInterviews'
import Filter from '../components/VideoInterviewsFilter'
import List from '../components/VideoInterviews'
import Spinner from '../components/Spinner'

class VideoInterviews extends Component {

  constructor(props) {
    super()

    this.state = {
      filter: {
        search: '',
        position: '',
      },
    }

    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleResetFilter = this.handleResetFilter.bind(this)
    this.openInterviewDetail = this.openInterviewDetail.bind(this)

    props.dispatch(loadInterviews())
  }

  handleFilterChange(event) {
    this.setState({
      filter: {
        ...this.state.filter,
        [event.target.name]: event.target.value,
      }
    })
  }

  handleResetFilter(event) {
    event.preventDefault()

    this.setState({
      filter: {
        search: '',
        position: '',
      }
    })
  }

  openInterviewDetail(interview) {
    this.props.history.push(`/video-interview/detail/${interview.id}`)
  }

  render() {
    let { loaded } = this.props

    // filter by search
    let interviews = this.props.interviews.filter(interview => {
      let search = this.state.filter.search.toLowerCase()

      if (interview.candidate.firstName.toLowerCase().indexOf(search) > -1) return true
      if (interview.candidate.lastName.toLowerCase().indexOf(search) > -1) return true
      if (interview.candidate.email.toLowerCase().indexOf(search) > -1) return true
      if (interview.jobPositinoLabel.toLowerCase().indexOf(search) > -1) return true

      return false
    })

    // filter by position
    if (this.state.filter.position !== '') {
      interviews = interviews.filter(interview => interview.jobPositinoLabel === this.state.filter.position)
    }

    return (
      <div>
        <Filter
          positions={this.props.positions}
          values={this.state.filter}
          onChange={this.handleFilterChange}
          onReset={this.handleResetFilter}
        />

        {!loaded &&
          <Spinner />
        }

        {loaded &&
          <List
            interviews={interviews}
            onClick={this.openInterviewDetail}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.videoInterviews.loaded && state.jobPositions.loaded,
    positions: state.jobPositions.list,
    interviews: state.videoInterviews.list,
  }
}

export default withRouter(connect(mapStateToProps)(VideoInterviews))
