import { getCountries } from './api'
import * as actionTypes from '../constants/actionTypes'

export function loadCountries() {
  return (dispatch, getState) => {
    // we already have data
    if (getState().countries.loaded) {
      return
    }

    // fetch data from api
    getCountries()
      .then((response) => {
        dispatch({
          type: actionTypes.COUNTRIES_DATA_LOAD,
          countries: response.data
        })
      })
  }
}
