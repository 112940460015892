import * as forms from '../constants/forms'

export const initialDataLoaded = function (state) {
  return state.identity !== null
    && state.instance !== null
    && state.currentLanguage !== null
    && state.languages.loaded
}

export const filterQuestions = (questions, filter) => {
  if (filter.search) {
    questions = questions.filter((question) => {
      return question.text.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
    })
  }

  if (filter.languageId) {
    questions = questions.filter(q => q.languageId === filter.languageId)
  }

  if (filter.tags.length > 0) {
    questions = questions.filter(q => {
      let matchedTags = filter.tags.filter(tag => {
        return q.tags.find(qtag => qtag.name.toLowerCase() === tag.name.toLowerCase()) !== undefined
      })

      return matchedTags.length > 0
    })
  }

  return questions
}

// returns questions list reduced of question specified in the except argument
export const reduceQuestions = (questions, except = []) => {
  return questions.filter(question => {
    return except.find(q => q.id === question.id) === undefined
  })
}

export const filterTags = (tags, filter) => {
  if (filter.search) {
    tags = tags.filter((tag) => {
      return tag.name.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
    })
  }

  if (filter.sortBy) {
    switch (filter.sortBy) {
      case forms.FILTER_OCCURENCE:
        tags = tags.concat().sort((a, b) => b.questions.length - a.questions.length)
        break

      case forms.FILTER_ALPHABET:
      default:
        tags = tags.concat().sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
    }
  }

  return tags
}

export const getTagQuestions = (questions, tag) => {
  return questions.filter(q => {
    return q.tags.find(qtag => qtag.name.toLowerCase() === tag.name.toLowerCase()) !== undefined
  })
}

export const filterSets = (sets, filters) => {
  if (filters.search) {
    sets = sets.filter((set) => {
      return set.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
    })
  }

  if (filters.languageId) {
    sets = sets.filter(s => s.languageId === filters.languageId)
  }

  return sets
}

export const sumTotalDuration = (questions) => {
  let duration = 0
  questions.map(q => duration += q.duration)

  return duration
}

export const getLanguageByCode = (languages, currentLanguageCode) => {
  if (languages.length === 1) { // there is only one language
    return languages.concat().pop()
  }

  languages = languages.filter(language => language.code === currentLanguageCode)

  if (languages.length > 0) {
    return languages.pop()
  }

  return null
}

export const filterEmailTemplatesByLanguage = (templates, languageId) => {
  return templates.filter(t => t.languageId === languageId)
}

export const getInactiveLanguages = (languages, activeLanguages) => {
  return languages.filter(language => {
    return activeLanguages.find(l => l.id === language.id) === undefined
  })
}

export const filterVideoInterviewLanguages = (languages) => {
  return languages.filter(language => language.isRecorderTranslated === true)
}

export const getInterfaceLanguages = (languages, activeLanguages) => {
  return languages.filter(language => {
    return language.isAppTranslated === true && activeLanguages.find(l => l.id === language.id) !== undefined
  })
}

export const visibleEmailTemplates = (emailTemplates, visibility) => {
  return emailTemplates.map(template => {
    return {
      ...template,
      visible: visibility[template.id] === true,
    }
  })
}

export const getInvitationLanguage = (state) => {
  return state.videoInterviewInvitation.languageId === 0
    ? getLanguageByCode(state.languages.list, state.currentLanguage).id
    : state.videoInterviewInvitation.languageId
}