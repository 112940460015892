import * as actionType from '../constants/actionTypes'

const initialState = {
  active: false,
  message: null,
  onConfirm: null,
  onCancel: null,
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.CONFIRM_DIALOG_SHOW:
      return {
        active: true,
        message: action.message,
        onConfirm: action.onConfirm,
        onCancel: action.onCancel,
      }

    case actionType.CONFIRM_DIALOG_CONFIRM:
    case actionType.CONFIRM_DIALOG_CANCEL:
      return {
        active: false,
        message: null,
        onConfirm: null,
        onCancel: null,
      }

    default:
      return state
  }
}
