import * as actionType from '../constants/actionTypes'

const initialState = {
  loaded: false,
  list: [],
  details: {},
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionType.VIDEO_INTERVIEWS_DATA_LOAD:
      return {
        ...state,
        loaded: true,
        list: action.interviews,
      }

    case actionType.VIDEO_INTERVIEWS_DETAILS_LOAD:
      return {
        ...state,
        details: {
          ...state.details,
          [action.id]: action.details,
        },
      }

    default:
      return state
  }
}
