import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import c from 'classnames'
import { __RouterContext as RouterContext, matchPath } from "react-router";
import { createLocation } from "history";

const NavLink = (
  {
    className,
    activeClassName,
    onlyActiveOnIndex,
    children,
    ...props
  }) => {
    return (
      <RouterContext.Consumer>
        {context => {
          const toLocation = createLocation(props.to, null, null, context.location);
          const { pathname: path } = toLocation;
          // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
          const escapedPath =
            path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

          const isActive = escapedPath
            ? matchPath(context.location.pathname, {
                path: escapedPath,
                exact: onlyActiveOnIndex,
              })
            : null;

          return (
            <li className={c(className, isActive ? activeClassName : null)}>
                <Link {...props}>
                  {children}
                </Link>
            </li>
          )
        }}
      </RouterContext.Consumer>
    )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  onlyActiveOnIndex: PropTypes.bool,
  children: PropTypes.node,
}

NavLink.defaultProps = {
  activeClassName: 'active',
  onlyActiveOnIndex: false,
  children: null,
}

NavLink.contextTypes = {
  router: PropTypes.object,
}

export default NavLink
