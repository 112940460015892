import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { loadSets } from '../actions/sets'
import { selectSet, createQuestion, updateQuestion, removeQuestion, sortQuestions } from '../actions/videoInterviews'
import Questions from '../components/VideoInterviewQuestionsStep'

class VideoInterviewQuestionsStep extends Component {

  constructor() {
    super()

    this.state = {
      showSets: false,
    }

    this.handleShowSets = this.handleShowSets.bind(this)
    this.handleSelectSet = this.handleSelectSet.bind(this)
  }

  handleShowSets(event) {
    event.preventDefault()

    let showSets = !this.state.showSets

    this.setState({
      showSets: showSets,
    })

    if (showSets) {
      this.props.dispatch(loadSets())
    }
  }

  handleSelectSet(event, set) {
    event.preventDefault()
    this.props.dispatch(selectSet(set)).then(() => {
      this.setState({
        showSets: false,
      })
    })
  }

  render() {
    return (
      <Questions
        onShowSets={this.handleShowSets}
        onSelectSet={this.handleSelectSet}
        showSets={this.state.showSets}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: state.videoInterviewInvitation.questions,
    sets: state.sets.list,
    setsLoaded: state.sets.loaded,
    languages: state.languages.list,
    nextStep: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview/invite/introduction-video')
    },
    cancel: (event) => {
      event.preventDefault()
      ownProps.history.push('/video-interview')
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action),
    onQuestionEdit: (question, values) => {
      return dispatch(updateQuestion(question, values))
    },
    onQuestionDelete: (event, question) => {
      event.preventDefault()
      dispatch(removeQuestion(question))
    },
    onQuestionCreate: (values) => {
      dispatch(createQuestion(values))
    },
    onQuestionSort: ({ oldIndex, newIndex }) => dispatch(sortQuestions(oldIndex, newIndex)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewQuestionsStep))
